import { BaseHeader } from 'components/header';
import { useEffect, useState } from 'react';

import { Checkbox, Col, Form, Layout, Row, Skeleton } from 'antd';

import AssessmentClock from 'img/assessment-intro-clock.svg';

import { DarkButton } from 'components/dark-button';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Routes } from 'routes';
import './Intro.scss';
import { oneThirdCol, twoThirdCol } from 'utils/antd';
import { Step } from './assessment-step';
import {
  initAssessmentData,
  QuestionnaireControlSliceReducer,
  questionnaireInfoInitialLoad,
  initialState as questionnaireInitialState
} from './questionnaire/questionnaire-control-flow-slice';
import { Collection } from 'documents/document';
import { useReducer } from 'hooks/useReducer';
import { isMobile } from 'react-device-detect';
import { Text, Title } from 'components/mvp-typography';
import { ConcussionRXLogo } from 'features/sign-in/v2/concussionRx-logo';

const stepsData = [
  {
    duration: '20 mins',
    title: 'Answer four questionnaires',
    description: 'To understand your symptoms',
    index: 1,
    endNode: false
  },
  {
    duration: '20 mins',
    title: 'Complete a neurocognitive screening tool',
    description: 'To evaluate your cognition',
    index: 2,
    endNode: true
  }
];

export function AssessmentIntro() {
  const [checked, setChecked] = useState(false);
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();

  const [questionnaireInfo, questionnaireInfoDispatch] = useReducer(
    QuestionnaireControlSliceReducer,
    questionnaireInitialState
  );

  useEffect(() => {
    questionnaireInfoDispatch(
      questionnaireInfoInitialLoad({
        patientId,
        rootPath: Collection.PendingPatientAssessments
      })
    );
  }, [patientId]);
  // eslint-disable-next-line
  function formSubmit(_e: Event) {
    // maybe we can submit the timestamp of 72 hours here to firestore to store;
    // that value timestamp can be referenced in comparison for the timer;
    // timestamp - time now doesn't exceed 72 hours
    questionnaireInfoDispatch(initAssessmentData(patientId));
    history.push(Routes.QuestionnaireFlow);
  }

  const patientIsDoingAnAssessment = questionnaireInfo.assessmentData !== null;
  if (questionnaireInfo.isLoading) return <Skeleton active />;

  /**
   * Redirect the user away from this page if they have already started an assessment
   */
  if (patientIsDoingAnAssessment) {
    return <Redirect to={Routes.QuestionnaireFlow} />;
  }

  /**
   * Show an error message that assessments cannot be completed on mobile devices
   */
  if (isMobile) {
    return <CrxMobileDeviceAssessmentBlocked />;
  }
  return (
    <Layout className="assessment-container">
      <BaseHeader />

      {/*hero container*/}
      <div className="assessment-container__hero">
        <div className="hero__container">
          <div className="hero__title crx-typography h1">
            {`Concussion isn't just a "yes" or "no" diagnosis.`}
            <br></br>
            Your subtype matters
          </div>
          <div className="hero__description crx-typography body-normal">
            No two concussions are the same. ConcussionRx precisely defines and
            breaks down your concussion by its &quot;complexity subtype&quot;,
            offering your healthcare provider a concussion assessment
            prescription for you, and specific symptom highlights for comparison
            over time to make sure you’re achieving your full recovery.{' '}
            <br></br>
            <br></br>
            For the most accurate results, it’s best to set aside 1 hour to
            complete these steps all in one go. If you have to take a break,
            don’t worry, you’ll have up to 72 hours to complete everything.
          </div>
        </div>
      </div>

      {/*steps*/}
      <div className="assessment-steps-container">
        <Row gutter={[16, 16]}>
          <Col {...twoThirdCol} style={{ height: '100%' }}>
            {stepsData.map((data, i) => (
              <Step
                key={i}
                duration={data.duration}
                title={data.title}
                description={data.description}
                index={data.index}
                endNode={data.endNode}
              />
            ))}
          </Col>
          <Col {...oneThirdCol}>
            <img width={340} src={AssessmentClock} />
          </Col>
        </Row>
      </div>

      {/*checkbox for time limit*/}
      <Form
        name="acknowledgement"
        onFinish={e => {
          formSubmit(e);
        }}
      >
        <div>
          <Row className="checkbox-container" style={{ marginBottom: '16px' }}>
            <Form.Item
              name="72-hr-acknowledgement"
              valuePropName="checked"
              required={true}
              rules={[
                {
                  validator() {
                    // _, value
                    // console.log("value: ", value, _, checked);``
                    if (checked === true) return Promise.resolve();
                    return Promise.reject(
                      'Please acknowledge the 72 hour agreement'
                    );
                  }
                }
              ]}
            >
              <Checkbox
                checked={checked}
                onChange={e => {
                  setChecked(e.target.checked);
                }}
              />
              <span className="checkbox-container__label crx-typography label-small">
                I acknowledge the 72 hour time limit to complete these steps.
                Otherwise, I’ll have to restart <br></br> the assessment to
                maintain medical accuracy.
              </span>
            </Form.Item>
          </Row>
          <Row style={{ marginLeft: '110px' }}>
            <Form.Item>
              <DarkButton
                className="crx-typography button-big"
                htmlType="submit"
              >
                {"OK. I'm ready!"}
              </DarkButton>
            </Form.Item>
          </Row>
        </div>
      </Form>
    </Layout>
  );
}

/**
 * Show an error message that assessments cannot be completed on mobile devices
 */
export function CrxMobileDeviceAssessmentBlocked() {
  return (
    <Layout className="assessment-container">
      <ConcussionRXLogo />
      <Title className="hero_title crx-typoghraphy h1">
        Please use a wide-screen device
      </Title>
      <Text>
        ConcussionRx assessments are designed to be done on wide-screen devices
        like a desktop computer.
      </Text>
      <Text>
        Please login on a device with a larger screen size to start or complete
        your assessment.
      </Text>
    </Layout>
  );
}
