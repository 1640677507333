import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Document,
  Collection,
  ExpirationPeriod,
  Patient,
  PatientProfile,
  PatientSubcollection,
  PatientSubSymptom,
  PatientSymptom,
  QualityOfLife,
  SubSymptom,
  Symptom,
  SymptomCalcInfo,
  SymptomSource,
  SymptomSources,
  SymptomValue,
  Inventory
} from 'documents';
import { difference, round, union } from 'lodash';
import { isNil, values } from 'lodash/fp';
import { getSymptomExpiredSources, isQolExpired } from 'utils/business';
import { handleError, handlePending, ThunkApi } from 'utils/redux';

import { createRepo } from 'api/firebase';

import { SymptomCardData } from '../../features/patient/dashboard/symptom-card';

import { TestRecord } from 'api/impact/types';
import {
  API_TO_COMPLEXITY_MAP,
  ConcussionComplexity
} from 'documents/concussion-complexity';
import moment from 'moment';
import { recalculateSubSymptoms } from '../../api/firebase';
import { createImpactInventoryRepo } from '../../features/patient/impact-questionnaire/impactAPISlice';
import {
  createNewDhi,
  DhiState
} from '../../features/patient/outcome-measures/dhi/dhiSlice';
import {
  createEmptyPromisInventory,
  createPromisRepo
} from '../../features/patient/outcome-measures/promis/promisSlice';
import {
  PatientInfoState,
  ConcussionAnalysis
} from './patient-info-slice-types';
import { userIsBeingOnboarded } from 'features/auth/userInitChecks';
import { Role } from 'features/auth/types';
import { ConcussionTreatmentRecommendation } from 'documents/patient-assessment/concussion-treatment-recommendation';
import { generateEnhancedTreatmentRecommendations } from './actions/patientInfoSlice-actions-generateEnhancedTreatmentRecommendations';
import { PatientSubtypeRangesResultInventory } from 'documents/patient-assessment/patient-subtype-range';

export const initialState: PatientInfoState = {
  isBeingOnboarded: false,
  qolValues: [],
  symptomValues: {},
  subSymptoms: {},
  patient: {
    displayName: '',
    email: ''
  },
  isLoading: true,
  hasError: false,
  qolExpired: false,
  symptomsExpired: false,
  concussion: {
    id: '',
    evaluatedOn: moment(),
    subtype: ConcussionComplexity.Minimal,
    'Assessment Recommendations': {
      'Counseling Rx': [],
      'Neuropsychology Rx': [],
      'Occupational Therapy Rx': [],
      'Physiotherapy Rx': []
    }
  },
  _concussion: {
    data: {
      subtype: 0
    },
    date: moment(),
    id: ''
  },
  dhi: new Array(12).fill(0).map(createNewDhi),
  promis: new Array(12).fill(0).map(createEmptyPromisInventory),
  impact: [],
  error: null,
  concussionTreatmentRecommendations: []
};

/**
 * Expects a patient-id as input
 */
export const patientInfoInitialLoad = createAsyncThunk(
  'patientInfo/initialLoadThunk',
  fetchPatientData
);

export async function fetchPatientData(patientId: string) {
  const symptomRepo = createRepoForPatient<PatientSymptom>(
    patientId,
    PatientSubcollection.Symptoms
  );
  const subSymptomRepo = createRepoForPatient<PatientSubSymptom>(
    patientId,
    PatientSubcollection.SubSymptoms
  );
  const qolRepo = createRepoForPatient<QualityOfLife>(
    patientId,
    PatientSubcollection.QualityOfLife
  );

  const patientRepo = createRepo<Patient>(Collection.Patients);
  const patientProfileRepo = createRepo<PatientProfile>(
    Collection.PatientProfiles
  );
  const symptomCalcInfo = createRepoForPatient<SymptomCalcInfo>(
    patientId,
    PatientSubcollection.SymptomCalcInfo
  );

  const dhiRepo = createDhiRepo(patientId);
  const promisRepo = createPromisRepo(patientId);

  const concussionRepo = createConcussionRepo(patientId);
  const enhancedConcussionTreatmentRecommendationRepo =
    createEnhancedConcussionTreatmentRecommendationRepo(patientId);
  const impactInventoryRepo = createImpactInventoryRepo(patientId);
  const patient = await patientRepo.find(patientId);
  if (!patient) throw new Error(`Patient (id=${patientId}) not found`);

  const patientProfile = await patientProfileRepo.find(patientId);

  const [
    symptoms,
    qols,
    symptomCalcInfoArr,
    subSymptoms,
    dhi,
    promis,
    impactTests,
    concussion,
    concussionTreatmentRecommendations
  ] = await Promise.all([
    symptomRepo.getList({ orderBy: ['date', 'desc'] }),
    qolRepo.getList({ orderBy: ['date', 'desc'] }),
    symptomCalcInfo.getList(),
    subSymptomRepo.getList({ orderBy: ['date', 'desc'] }),
    dhiRepo.getList({ orderBy: ['date', 'desc'] }),
    promisRepo.getList({ orderBy: ['date', 'desc'] }),
    impactInventoryRepo.getList({ orderBy: ['date', 'desc'] }),
    concussionRepo.getList({ orderBy: ['date', 'desc'] }),
    enhancedConcussionTreatmentRecommendationRepo
      .getList({
        orderBy: ['date', 'desc']
      })
      .catch(() => [])
  ]);

  return {
    symptoms,
    qols,
    patient,
    symptomCalcInfoArr,
    subSymptoms,
    dhi,
    promis,
    impactTests,
    concussion,
    patientProfile,
    isBeingOnboarded: await userIsBeingOnboarded({
      uid: patientId,
      role: Role.Patient
    }),
    concussionTreatmentRecommendations
  };
}

export const setExtraRecommendations = createAsyncThunk<
  ConcussionAnalysis | null,
  string,
  ThunkApi<PatientInfoState>
>(
  'patientInfo/set-extra-recommendations',
  async (extraRecommendations: string, state) => {
    const {
      patient: { id },
      _concussion
    } = state.getState();
    if (id === undefined) throw new Error(`Patient ID is undefined`);
    const concussionRepo = createConcussionRepo(id);
    const newObj: ConcussionAnalysis = { ..._concussion, extraRecommendations };
    await concussionRepo.set(newObj);
    return newObj;
  }
);

const slice = createSlice({
  name: 'patientDashboard',
  initialState,
  reducers: {
    stopLoading(state) {
      state.isLoading = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(patientInfoInitialLoad.pending, handlePending);
    builder.addCase(patientInfoInitialLoad.rejected, (...args) =>
      handleError(...args, 'PatientInfoSlice')
    );
    builder.addCase(patientInfoInitialLoad.fulfilled, (state, { payload }) => {
      const { symptoms, qols, patient, symptomCalcInfoArr, subSymptoms } =
        payload;

      /**
       * Mark if the user is being onboarded
       */
      state.isBeingOnboarded = payload.isBeingOnboarded;

      state.patient = patient;
      state.patient.birthDate = payload.patientProfile?.birthDate;
      state.concussionTreatmentRecommendations =
        payload.concussionTreatmentRecommendations;

      state.qolValues = qols;

      // Transform the symptoms array to a SymptomCardData list
      state.symptomValues = transformToSymptomCardData(
        Symptom,
        symptoms as DateDataCollection[]
      );

      // Transform the sub-symptoms array to a SymptomCardData list
      state.subSymptoms = transformToSymptomCardData(
        SubSymptom,
        subSymptoms as DateDataCollection[]
      );

      /**
       * Save promis and dhi data
       */
      state.dhi = payload.dhi;
      state.promis = payload.promis;

      // If there is at least one concussion
      if (payload.concussion.length >= 1) {
        const concussion = payload.concussion[0];
        state._concussion = concussion;

        state.concussion = {
          id: concussion.id,
          extraRecommendations: concussion.extraRecommendations,
          // Get the first concussion analysis
          // WARN: Typecast to any
          ...(concussion.data as any),
          // TODO: Where does this value come from
          evaluatedOn: payload.concussion[0].date,

          /**
           * Map the API subtype to the frontend subtype enum
           * TODO: Standardize the enum
           */
          subtype:
            API_TO_COMPLEXITY_MAP[
              payload.concussion[0].data.subtype as any as 0 | 1 | 2 | 3 | 4
            ]
        };
      }

      state.impact = payload.impactTests.map(transformImpactTestToInventory);

      /**
       * TODO patient.inventoryExpirations is always undefined;
       * getSources needs to be updated(?) to include everything
       * should have a 30 day expiration
       */
      const { missing, expired } = getSources(
        symptomCalcInfoArr,
        symptoms,
        patient.inventoryExpirations
      );

      // redo calculations of missingSymptomCalcInfo on load
      const missingSymptomCalcInfo = symptomCalcInfoArr.filter(
        calc => calc.status == 'fail'
      );

      const hasMissingSymptoms = missingSymptomCalcInfo.length > 0;
      if (hasMissingSymptoms) {
        const missingSymptoms: string[] = [];
        for (const symptom of missingSymptomCalcInfo) {
          if (symptom.id) {
            missingSymptoms.push(symptom.id.replace(/\s/g, ''));
          }
        }
        missingSymptoms.map(
          symptom => Symptom[symptom as keyof typeof Symptom]
        );
        recalculateSubSymptoms({
          patientId: `${patient.id}`,
          symptoms: missingSymptoms,
          subSymptoms: []
        })
          // .then((result) => {
          //   console.log("this is result: ",result.data);
          // })
          .catch(error => {
            console.log(
              'An error occurred calculating symptoms: ',
              error.message
            );
          });
      }
      // end redo calculations of missingSymptomCalcInfo on load

      state.expiredSources = expired;
      state.missingSources = missing;

      state.symptomsExpired =
        (expired && Boolean(expired.length)) ||
        (missing && Boolean(missing.length));

      const nonExpiredQoLs = qols.filter(e => !isQolExpired(e.date));
      /**
       * TODO: qolExpired should be updated here for individual
       * qol expiration and not batch qol expiration
       * it's already done in the front end of the reducer so
       * we should update this and just read off the reducer
       * instead of calculating on the front end
       */
      state.qolExpired = !(
        nonExpiredQoLs.some(v => v.dailyLiving) &&
        nonExpiredQoLs.some(v => v.physicalActivity) &&
        nonExpiredQoLs.some(v => v.selfRating)
      );

      state.isLoading = false;
    });

    builder.addCase(setExtraRecommendations.pending, handlePending);
    builder.addCase(setExtraRecommendations.rejected, (...args) =>
      handleError(...args, 'PatientInfoSlice')
    );
    builder.addCase(setExtraRecommendations.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (payload === null) return;
      // Save the updated object to state
      state._concussion = payload;
      state.concussion.extraRecommendations = payload.extraRecommendations;
    });

    builder.addCase(
      generateEnhancedTreatmentRecommendations.pending,
      handlePending
    );
    builder.addCase(
      generateEnhancedTreatmentRecommendations.fulfilled,
      state => {
        state.isLoading = false;
      }
    );

    builder.addCase(
      generateEnhancedTreatmentRecommendations.rejected,
      handleError
    );
  }
});

/**
 * TODO: Improve typing
 */
export const createConcussionRepo = (patientId: string) =>
  createRepo<ConcussionAnalysis>(
    [
      // WARN: USING LEGACY PATIENTS COLLECTION FOR ASSESSMENT DATA
      Collection.Patients,
      patientId,
      'concussionClassifications'
    ].join('/')
  );

export const createEnhancedConcussionTreatmentRecommendationRepo = (
  patientId: string
) =>
  createRepo<ConcussionTreatmentRecommendation>([
    Collection.PatientAssessments,
    patientId,
    'inventoriesConcussionTreatmentRecommendations'
  ]);

export const createSubtypeRangeRepo = (patientId: string) =>
  createRepo<PatientSubtypeRangesResultInventory>([
    Collection.PatientAssessments,
    patientId,
    PatientSubcollection.SubtypeRangeResults
  ]);

/**
 * Create a repo object for DHI data access
 * WARN: USING LEGACY PATIENTS COLLECTION FOR ASSESSMENT DATA
 */
const createDhiRepo = (patientId: string) =>
  createRepo<DhiState['dhi']>(
    `${Collection.Patients}/${patientId}/${PatientSubcollection.InventoriesDhi}`
  );

type DateDataCollection = { date: moment.Moment } & Record<
  string,
  SymptomValue
>;
/**
 * Helper method to transform an array of symptom-like
 * objects to SymptomCardData
 *
 * @param keysObj
 * @param iterable
 * @returns
 */
function transformToSymptomCardData(
  keysObj: Record<string, any>,
  iterable: DateDataCollection[]
): Record<string, SymptomCardData[]> {
  // Iterate through the keys object
  return values(keysObj).reduce(
    (acc, name) => ({
      // Use the values of the previous iteration
      ...acc,
      // Create a new sub-object with the current key
      [name]: iterable
        // Find all objects whe
        .filter(topLevelObject => !isNil(topLevelObject[name]?.value))
        .map(
          topLevelObject =>
            ({
              date: topLevelObject.date,
              value: round(topLevelObject[name]!.value, 1)
            } as SymptomCardData)
        )
    }),
    {}
  );
}

/**
 * Helper function to create a firebase repo object
 * to access patient information
 *
 * WARN: USING LEGACY PATIENTS COLLECTION FOR ASSESSMENT DATA
 * @param patientId
 * @param type
 * @returns
 */
const createRepoForPatient = <T extends Document>(
  patientId: string,
  type: PatientSubcollection
) => createRepo<T>(`${Collection.Patients}/${patientId}/${type}`);

function getSources(
  symptomCalcInfoArr: SymptomCalcInfo[],
  symptoms: PatientSymptom[],
  inventoryExpirations?: ExpirationPeriod
) {
  const missingSources = symptomCalcInfoArr
    .filter(x => x.status === 'fail')
    .reduce((acc: SymptomSource[], info: SymptomCalcInfo) => {
      return info.missingSources ? [...acc, ...info.missingSources] : acc;
    }, []);

  const expiredSources = Object.values(Symptom).reduce(
    (acc: SymptomSource[], symptom: Symptom) => {
      const patientSymptom = symptoms.find(x => x[symptom] != undefined);
      return patientSymptom
        ? union(
            acc,
            getSymptomExpiredSources(
              symptom,
              patientSymptom,
              inventoryExpirations
            )
          )
        : acc;
    },
    []
  );

  const noValueSources = Object.values(Symptom).reduce(
    (acc: SymptomSource[], symptom: Symptom) => {
      // Find the first symptom that has a value
      const patientSymptom = symptoms.find(x => x[symptom] !== undefined);
      const isCalculated =
        symptomCalcInfoArr.find(x => x.id === symptom) !== undefined;
      return patientSymptom || isCalculated
        ? acc
        : [...acc, ...SymptomSources[symptom]];
    },
    []
  );

  // Expired sources that don't present in SymptomCalcInfo docs
  const expiredTotal = difference(expiredSources, missingSources);

  /*
    Unique source values from SymptomCalcInfo docs and
    values from noValueSources that not included in expired sources
  */
  const missingTotal = union(
    difference(noValueSources, expiredSources),
    missingSources
  );

  return {
    missing: missingTotal,
    expired: expiredTotal
  };
}

/**
 * Transforms an impact test to inventory
 * @param impactTest A test from ImPACT
 * @returns An Inventory object that stores the results of the ImPACT test
 */
function transformImpactTestToInventory(impactTest: Inventory & TestRecord) {
  /**
   * Extract the values that are relevant
   *
   * The ImPACT API specifies these values can be undefined.
   * This is not expected to happen but to be safe a default value is set
   */
  const {
    userMemoryCompositeScoreVerbal = 0,
    userImpulseControlCompositeScore = 0,
    userMemoryCompositeScoreVisual = 0,
    userReactionTimeCompositeScore = 0,
    userVisualMotorCompositeScore = 0
  } = impactTest;

  return {
    date: moment(impactTest.date),
    data: {
      userMemoryCompositeScoreVerbal,
      userImpulseControlCompositeScore,
      userMemoryCompositeScoreVisual,
      userReactionTimeCompositeScore,
      userVisualMotorCompositeScore
    }
  };
}
export const PatientInfoSliceReducer = slice.reducer;
export const PatientInfoSliceActions = slice.actions;
export default slice.reducer;
