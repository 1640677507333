import { Document } from 'documents/document';
import { httpsCallableWrapper } from '../firebase-api';
import { z } from 'zod';

export interface ClinicianQueryArgs {
  question: string;
}

export const clinicianQueryResponseReferenceSchema = z.object({
  id: z.string(),
  title: z.string(),
  abstract: z.string(),
  publicationTypes: z.array(z.string())
});

export interface ClinicianQueryResponseReference extends Document {
  id: string;
  title: string;
  abstract: string;
  publicationTypes: string[];
}

export interface ClinicianQueryResponse {
  role: string;
  chatMessage: { role: 'assistant'; content: string };
  references: ClinicianQueryResponseReference[];
}

export const clinicianQuery = (args: ClinicianQueryArgs) =>
  httpsCallableWrapper<any, ClinicianQueryResponse>('clinic-query')(args);
