import { Card, Collapse, Flex, Tag } from 'antd';
import { ClinicianQueryResponseReference } from 'api/firebase/apis/clinician-query';
import { Link, Text } from 'components/mvp-typography';
import { grey, theme } from 'styles/colors';
import { Message } from '../types';
import { ChatMessage } from './message';

export interface QuestionResponseGroup {
  id: string;
  question: { message: Message };
  response?: {
    message: Message;
    references: ClinicianQueryResponseReference[];
  };
}

export function QuestionResponseSet(props: {
  responseGroup: QuestionResponseGroup;
}) {
  const { response, question } = props.responseGroup;
  return (
    <div style={{ width: '100%' }}>
      <div>
        <ChatMessage content={question.message.content} />
      </div>
      {response && (
        <>
          <ChatMessage
            content={response.message.content ?? ''}
            noBackground
            color={theme.secondary}
          />
          <div>
            <Collapse
              // bordered={false}
              style={{
                background: 'none'
              }}
              defaultActiveKey={'results'}
            >
              <Collapse.Panel key={'results'} header={<Text>Results</Text>}>
                {response.references.map((reference, index) => (
                  <ResearchResult
                    key={`reference-${reference.id}`}
                    reference={reference}
                    index={index}
                  />
                ))}
              </Collapse.Panel>
            </Collapse>
          </div>
        </>
      )}
    </div>
  );
}

interface ReferenceResultProps {
  reference: ClinicianQueryResponseReference;
  index: number;
}

const ReferenceTag = (props: { publicationType: string }) => (
  <Tag
    style={{
      borderRadius: '100px',
      background: '#C6ABFF4D',
      borderColor: '#B277FF80',
      color: '#230056B2'
    }}
  >
    {props.publicationType}
  </Tag>
);

function ResearchResult(props: ReferenceResultProps) {
  const pubmedLink = `https://pubmed.ncbi.nlm.nih.gov/${props.reference.id}/`;

  return (
    <>
      <Flex vertical gap={'small'}>
        <Text>
          {props.index + 1}. {props.reference.title}
        </Text>
        <Card
          style={{
            borderRadius: '10px',
            background: grey['light']
          }}
        >
          <Link href={pubmedLink}>{pubmedLink}</Link>
        </Card>
        <div
          style={{
            marginTop: '0.5rem',
            marginBottom: '0.5rem'
          }}
        >
          {props.reference.publicationTypes.map((publicationType, index) => (
            <ReferenceTag
              key={`referencetag-${index}`}
              publicationType={publicationType}
            />
          ))}
        </div>
      </Flex>
      <Collapse
        bordered={false}
        style={{
          background: 'none',
          fontSize: '12px !important'
        }}
      >
        <Collapse.Panel key={props.reference.id} header="Show abstract">
          <Text color={'#767679'} style={{ whiteSpace: 'preserve' }}>
            {props.reference.abstract}
          </Text>
        </Collapse.Panel>
      </Collapse>
    </>
  );
}
