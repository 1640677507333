import { Form, FormItemProps, Input, Select } from 'antd';
import { FormQuestion } from '../types/form-questions';

interface _FormArgs {
  firstName: string;
  lastName: string;
  email: string;
}

export type FormArgs = _FormArgs & Record<string, string>;

/**
 * A typed form item
 *
 * This is a new function instead of an alias because eslint was throwing a parsing error
 */
export const FormItem = (props: FormItemProps<FormArgs>) => (
  <Form.Item {...props} />
);
export function RenderQuestion({ question }: { question: FormQuestion }) {
  if (question.type === 'string') {
    return (
      <FormItem
        name={question.name}
        label={question.label}
        rules={question.rules}
      >
        <Input disabled={question.disabled} />
      </FormItem>
    );
  }
  if (question.type === 'select') {
    return (
      <FormItem
        name={question.name}
        label={question.label}
        rules={question.rules}
      >
        <Select
          options={question.options}
          disabled={question.disabled}
          loading={question.loading}
        />
      </FormItem>
    );
  }
  if (question.type === 'custom') {
    return (
      <FormItem
        name={question.name}
        label={question.label}
        rules={question.rules}
      >
        <question.component {...question} />
      </FormItem>
    );
  }
  throw new Error(`Unknown type '${(question as any).type}'`);
}
