import './PatientOnboardingProfile.scss';

import { useCallback, useEffect, useState } from 'react';

import {
  Collection,
  OnboardingStep,
  PatientOnboarding,
  PatientProfile,
  PatientProfile2,
  PatientSubcollection
} from 'documents';

import { useDocument } from 'hooks/firestore';

import { useAppSelector } from 'app/rootReducer';
import { OnboardingProgress, Page, ProfileForm2 } from 'components';
import { SignOutButton } from 'components/sign-out-button';

import { PatientRoutePrefix } from 'routes';

import { ConfigProvider } from 'antd';
import { createRepo } from 'api/firebase';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { validateProfileForm2 } from 'utils/validators';

export function PatientOnboardingProfile() {
  const [formState, setFormState] = useState({
    disabled: false,
    loading: false
  });

  const user = useAppSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const { hasError, isLoading, doc, update } = useDocument<PatientProfile2>({
    path: Collection.PatientProfiles,
    id: user!.uid
  });

  const { update: updateOnboarding } = useDocument<PatientOnboarding>({
    path: [
      Collection.Patients,
      user!.uid,
      PatientSubcollection.Onboardings
    ].join('/')
  });

  const onUpdate = useCallback(
    async (input: PatientProfile) => {
      await update(input as PatientProfile2);

      // Validated on input of upcoming changes
      const isInvalid = validateProfileForm2(input) ? false : true;
      setFormState({
        disabled: isInvalid,
        loading: false
      });
    },
    [update]
  );

  /**
   * Load the patient profile into the page
   */
  useEffect(() => {
    // The document does not exist
    if (!doc) return;

    // Validate the incoming document
    onUpdate(doc);
  }, [doc, onUpdate]);

  async function onDone() {
    setFormState({ disabled: true, loading: true });
    await updateOnboarding(
      {
        lastCompletedStep: OnboardingStep.Profile
      },
      true
    );

    const onboardingRepo = createRepo<PatientOnboarding>([
      Collection.Patients,
      user!.uid,
      PatientSubcollection.Onboardings
    ]);

    /**
     * Spin-lock until the document is available
     */
    while ((await onboardingRepo.first()) === undefined) {
      await asyncWait(100);
    }
    dispatch(push(PatientRoutePrefix));
  }

  return (
    <Page isLoading={isLoading} hasError={hasError}>
      {/**
       * Change the theme only for this page.
       * If the styles are used across the whole app then this should be moved to App.tsx
       *
       * https://ant.design/docs/react/customize-theme#nested-theme
       */}
      <ConfigProvider
        theme={{
          token: {
            /**
             * Design tokens of select components
             * https://ant.design/components/select#design-token
             */
            colorBgContainer: 'none',
            colorBorder: '#767679',
            colorText: '#767679'
          }
        }}
      >
        <SignOutButton rounded />
        <OnboardingProgress />
        <ProfileForm2
          submitDisabled={formState.disabled}
          submitLoading={formState.loading}
          /**
           * Initialise `impactLocation` to an empty array
           * WARN: This is needed because the validation code assumes `impactLocation` exists
           * but `doc` doesn't contain it
           **/
          value={{ impactLocation: [], ...doc }}
          onChange={onUpdate}
          onDone={onDone}
        />
      </ConfigProvider>
    </Page>
  );
}

function asyncWait(ms: number) {
  return new Promise<void>(res => {
    setTimeout(() => res(), ms);
  });
}
