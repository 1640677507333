import { Tag } from 'antd';
import clsx from 'clsx';
import { useState } from 'react';
import {
  UserEditableDetailsModal,
  UserEditableDetailsModalProps
} from '../user-add-modal';
import { BaseTableUserAPIResponse } from '../user-table/user-table-types';
import { ActionTagRenderProps } from './action-tag-component';

export interface ActionTagEditDetailsProps<T extends BaseTableUserAPIResponse>
  extends ActionTagRenderProps<T> {
  /**
   * The questions to display when adding or editing a user
   */
  userAddQuestions: UserEditableDetailsModalProps['questions'];
  /**
   * Set the initial values of the form. The keys should match the questions `names` of `userAddQuestions: FormQuestion[]`.
   */
  initialValues: UserEditableDetailsModalProps['initialValues'];
}

/**
 * Edit details tag.
 *
 * Renders a `<Tag />` and a modal
 * @param props
 * @returns
 */
export function TagEditDetails<T extends BaseTableUserAPIResponse>(
  props: ActionTagEditDetailsProps<T>
) {
  const [state, setState] = useState({
    modal: {
      isOpen: false
    }
  });

  return (
    <>
      {
        /**
         * Only render the modal when it is open
         */
        state.modal.isOpen && (
          <UserEditableDetailsModal
            onSubmit={data => {
              return props.onClick(
                {
                  ...props.record,
                  ...data
                },
                data
              );
            }}
            submitText="Save details"
            actionProcessingDescription="Updating details. Please do not close or reload this window or tab."
            isOpen={state.modal.isOpen}
            setIsOpen={isOpen =>
              setState(s => ({ ...s, modal: { ...s.modal, isOpen } }))
            }
            initialValues={props.initialValues}
            questions={props.userAddQuestions}
          />
        )
      }
      <Tag
        {...props.tagStyles}
        className={clsx('clickable', props.disabled && 'disabled')}
        style={{ padding: '0.1rem' }}
        onClick={() => {
          setState(s => ({ ...s, modal: { ...s.modal, isOpen: true } }));
        }}
      >
        {props.name}
      </Tag>
    </>
  );
}
