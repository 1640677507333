import { Button, ConfigProvider, Input, InputRef, Space } from 'antd';
import { SearchProps } from 'antd/lib/input';
import { Icon } from 'components/icon';
import React, { useRef } from 'react';

/**
 * A search bar
 *
 * @param props
 * @returns
 */
export function CrxSearch(
  props: SearchProps & {
    actionIconAtRight?: boolean;
    actionIcon?: React.ReactNode;
  }
) {
  const ref = useRef<InputRef>(null);
  function submit(el: Pick<InputRef, 'input'> | null) {
    if (el === null) {
      return;
    }

    if (el.input === null) {
      props.onSearch?.('');
      return;
    }
    props.onSearch?.(el.input.value);
  }
  return (
    <ConfigProvider
      prefixCls="crx-search-component"
      theme={{
        components: {
          Input: {
            colorBorder: 'transparent',
            borderRadius: 100
          },
          Button: {
            colorBorder: 'transparent',
            borderRadius: 100
          }
        }
      }}
    >
      <Space.Compact className="crx-search-component">
        {!props.actionIconAtRight && (
          <Button
            className="crx-search-component--button"
            style={{
              background: 'white',
              color: 'black',
              borderColor: 'transparent'
            }}
            icon={<Icon size="sm">search</Icon>}
            onClick={() => {
              submit(ref.current);
            }}
          />
        )}

        <Input
          name="crx-search-component-bar"
          ref={ref}
          {...props}
          prefix={null}
          onPressEnter={val => {
            const el = val.target as HTMLInputElement;
            submit({ input: el });
          }}
          allowClear
          className='"crx-search-component--input'
          style={{ borderColor: 'transparent' }}
        />
        {props.actionIconAtRight && (
          <Button
            className="crx-search-component--button"
            style={{
              background: 'white',
              color: 'black',
              borderColor: 'transparent'
            }}
            icon={<Icon size="sm">search</Icon>}
            onClick={() => {
              submit(ref.current);
            }}
          />
        )}
      </Space.Compact>
    </ConfigProvider>
  );
}
