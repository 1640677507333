import { Flex, Skeleton } from 'antd';
import { createRepo } from 'api/firebase';
import { useAppSelector } from 'app/rootReducer';
import { PrintableRootPage } from 'components/page/page-2';
import { Clinician } from 'documents/clinician';
import { Collection } from 'documents/document';
import { User } from 'features/auth/authSlice';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import { useEffect, useState } from 'react';
import { resolveError } from 'utils/formatters/error/resolve-error';
import { useClinicInfo } from '../clinic-info-edit/useClinicInfo';
import { ClinicianTable } from './components/user-table/user-table-clinician';
import { PatientTable } from './components/user-table/user-table-patient/user-table-patient';

export default function ClinicUserManagement() {
  const { user, isUserDataLoading } = useAppSelector(s => s.auth);
  const { clinicId, error } = useResolveCurrentClinicInfo(
    user,
    isUserDataLoading
  );

  return (
    <PrintableRootPage title="User management">
      <Flex vertical gap={80}>
        {clinicId && (
          <>
            <div>
              <PatientTable clinicId={clinicId} />
            </div>
            <div>
              <ClinicianTable clinicId={clinicId} />
            </div>
          </>
        )}
        {!clinicId && !error && <Skeleton active />}
        {error && (
          <ErrorDisplay
            title="Something went wrong when loading clinician information"
            description={`Please report this error to contact@concussionrx.com`}
            error={error}
          />
        )}
      </Flex>
    </PrintableRootPage>
  );
}

/**
 * Resolve the current clinic from the clinician credentials
 *
 * TODO: Resolve this through a context and higher in the app
 * @param user
 * @param isUserDataLoading
 * @returns
 */
function useResolveCurrentClinicInfo(
  user: User | null,
  isUserDataLoading: boolean
) {
  const [clinicId, setClinicId] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    async function setup(user: User | null, isUserDataLoading: boolean) {
      if (isUserDataLoading) return;
      if (!user) throw new Error(`You must be logged in`);

      const clinicianRepo = createRepo<Clinician>(Collection.Clinicians);
      const clinicianInfo = await clinicianRepo.find(user.uid);
      if (!clinicianInfo)
        throw new Error(`Clinician (id=${user.uid}) not found`);
      clinicianInfo?.clinics[0];
      setClinicId(clinicianInfo?.clinics[0]);
    }
    setup(user, isUserDataLoading)
      .then(() => {
        setError(null);
      })
      .catch(error => {
        setError(resolveError(error));
      });
  }, [user, isUserDataLoading]);

  const clinicInfo = useClinicInfo(clinicId);
  return { clinicId, clinicInfo, error };
}
