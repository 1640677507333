import { Col, Flex, Row, Typography } from 'antd';
import { TitleProps } from 'antd/es/typography/Title';
import clsx from 'clsx';
import { PatientInfoContext } from 'components/patient-info/hook-context-patientinfo';
import { Symptom } from 'documents';
import {
  ToggleWithLabel,
  SymptomCard2,
  SymptomCard2ContextProvider
} from 'features/patient/dashboard/symptom-card/symptom-card-2';
import { useContext, useState } from 'react';
import { halfCol } from 'utils/antd';
import { getFirstName, pluralize } from 'utils/string/normalise';
import {
  brainFunctionCategories,
  evaluateBrainFunctionVisualisation
} from '../../../../utils/business/brainfunction-categories';

const CategoryTitle = (props: TitleProps) => (
  <Typography.Title level={3} className="patient-dashboard__title" {...props} />
);

export interface YourConcussionCardProps {
  isPointSizeIncreasing: boolean;
  getLink: (symptom: Symptom) => string;
}

/**
 * Display metrics about a patient over time
 */
export function YourConcussionCard() {
  const { patientInfo } = useContext(PatientInfoContext);

  const firstName = getFirstName(patientInfo.patient.displayName ?? '');
  const [showNumbers, setShowNumbers] = useState(false);
  return (
    <SymptomCard2ContextProvider
      value={{
        showNumbers
      }}
    >
      <Typography.Title level={2} className="patient-dashboard__title2">
        {`${pluralize(firstName)} Brain on Concussion`}
      </Typography.Title>

      <Typography.Paragraph>
        {`Understanding ${pluralize(
          firstName
        )} concussion and its impact on critical functional systems. ` +
          `These are the foundation for the recommendations to follow.`}
      </Typography.Paragraph>

      <ToggleWithLabel
        checked={showNumbers}
        setChecked={setShowNumbers}
        label={curVal => `${curVal ? 'Hide' : 'Show'} all scores`}
        customStyles={{
          flexStyle: { justifyContent: 'end', paddingTop: '10px' },
          textStyle: { fontSize: 16, fontWeight: 'bold' }
        }}
      />
      <Flex vertical gap={'large'}>
        {brainFunctionCategories.map(category => {
          // Render the category
          return (
            <div
              key={`brainfunction-category-${category.title}`}
              className={clsx(
                'brainfunction-category',
                category.visualisations.length > 2 && 'print-big'
              )}
            >
              <CategoryTitle>{category.title}</CategoryTitle>
              <Row gutter={[16, 16]} className={clsx('brainfunction-measure')}>
                {
                  // Render all visualisations in the category
                  category.visualisations.map((unknownVisualisation, i) => {
                    const brainFunction = evaluateBrainFunctionVisualisation(
                      unknownVisualisation,
                      patientInfo
                    );
                    const data = brainFunction.getValues(patientInfo);

                    return (
                      <Col
                        {...halfCol}
                        key={i}
                        className="brainfunction-measure-col"
                      >
                        <SymptomCard2
                          label={brainFunction?.label}
                          data={data}
                          scale={brainFunction.scale}
                          brainFunction={brainFunction}
                          disabled={
                            // If the data for the symptom is empty (falsy)
                            data.length === 0
                          }
                          // link={props.getLink(symptom)}
                        />
                      </Col>
                    );
                  })
                }
              </Row>
            </div>
          );
        })}
      </Flex>
    </SymptomCard2ContextProvider>
  );
}
