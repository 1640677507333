import { Input, InputProps, Select, Tag } from 'antd';
import {
  addPatient,
  clinicActionAllowPatientAssessments,
  clinicActionDisablePatientAssessments,
  clinicActionEditPatientDetails,
  clinicActionGetInvitationLink,
  clinicActionResendInvitation,
  listPatients
} from 'api/firebase/firebase-api';
import clsx from 'clsx';
import { Patient } from 'documents/patient';
import { useMemo } from 'react';
import { stringOrNull } from 'utils/string/normalise';
import {
  ActionState,
  ActionTagSettings
} from '../../action-tag/action-tag-component';
import { TagEditDetails } from '../../action-tag/tag-edit-details';
import UserTable from '../user-table';
import { UserListProps } from '../user-table-types';
import { useResolvePatientUserAddQuestions } from './useResolvePatientUserAddQuestions';

const apiAddUser: UserListProps<Patient>['apiAddUser'] = (user, clinicId) =>
  addPatient({ ...(user as any), clinicId });

type PatientRecord = Patient & {
  assignedClinicians: { id: string; name: string }[];
};

const BASE_ACTIONS: ActionTagSettings<PatientRecord>[] = [
  {
    name: p =>
      p.raw.canStartAssessments ? 'Activated' : 'Activate Assessment',
    onClick: async p => {
      if (p.raw.id === undefined) {
        throw new Error('ID is undefined. Please try reloading the page');
      }
      return clinicActionAllowPatientAssessments(p.raw.id);
    },
    shouldShow: p => !p.invitePending,
    initialState: p =>
      p.raw.canStartAssessments ? ActionState.SUCCESS : ActionState.NEUTRAL,
    toolTip: p =>
      p.raw.canStartAssessments
        ? `This patient can start new assessments`
        : `Click to allow this patient to start new assessments`
  },
  {
    name: p =>
      !p.raw.canStartAssessments
        ? '[NOT SHOWN IN PRODUCTION] Enable Assessment'
        : '[NOT SHOWN IN PRODUCTION] Disable Assessment',
    onClick: async p => {
      if (p.raw.id === undefined) {
        throw new Error('ID is undefined. Please try reloading the page');
      }
      return clinicActionDisablePatientAssessments(p.raw.id);
    },
    shouldShow: p =>
      ['local', 'development', 'qa'].includes(
        process.env.REACT_APP_CONCUSSIONRX_DEPLOYMENT as string
      ) && !p.invitePending,
    initialState: p =>
      !p.raw.canStartAssessments ? ActionState.SUCCESS : ActionState.NEUTRAL,
    toolTip: p =>
      p.raw.canStartAssessments
        ? `This patient cannot start new assessments`
        : `Click to disable this patient from starting new assessments`
  },
  {
    name: 'Resend invite',
    shouldShow: p => p.invitePending,
    initialState: () => ActionState.NEUTRAL,
    onClick: p => {
      if (!p.raw.id) {
        throw new Error('ID is undefined. Please try reloading the page');
      }
      return clinicActionResendInvitation({
        invitationId: p.raw.id,
        resendType: 'email'
      });
    }
  }
];

const api: UserListProps<PatientRecord>['api'] = ({
  clinicId,
  lastUserId,
  lastPendingUserId
}) =>
  listPatients({
    clinicId,
    usersLimit: 10,
    pendingUsersLimit: 10,
    ...(lastPendingUserId && {
      pendingUsersLastDocId: lastPendingUserId
    }),
    ...(lastUserId && { usersLastDocId: lastUserId })
  });

export function PatientTable(props: { clinicId: string }) {
  const patientUserAddQuestions = useResolvePatientUserAddQuestions(
    props.clinicId
  );
  /**
   * Actions that can appear for every row of the table
   */
  const actions: ActionTagSettings<PatientRecord>[] = useMemo(
    () => [
      ...BASE_ACTIONS,
      {
        name: 'Send SMS invite',
        shouldShow: p => p.invitePending,
        initialState: () => ActionState.NEUTRAL,
        render: _props => {
          /**
           * If the phone number is already set then skip the edit-user modal
           */
          if (_props.record.raw.phoneNumber) {
            return (
              <Tag
                {..._props}
                {..._props.tagStyles}
                className={clsx('clickable', _props.disabled && 'disabled')}
                onClick={() => {
                  _props.onClick(_props.record, {
                    type: 'existing_number'
                  });
                }}
              >
                {_props.name}
              </Tag>
            );
          }

          /**
           * The phoneNumber is not set, render a tag to set the property
           */
          return (
            <TagEditDetails
              {..._props}
              userAddQuestions={patientUserAddQuestions.filter(
                _ => _.name === 'phoneNumber'
              )}
              initialValues={{
                phoneNumber: _props.record.raw.phoneNumber
              }}
              onClick={(record, extra) => {
                _props.onClick(record, {
                  type: 'new_number',
                  ...extra
                });
              }}
            />
          );
        },
        onClick: async (
          record,
          extraData:
            | { type: 'new_number'; phoneNumber: string | null }
            | { type: 'existing_number' }
        ) => {
          /**
           * Throw an error if the ID is not available
           */
          if (!record.raw.id) {
            throw new Error('ID is undefined. Please try reloading the page');
          }

          /**
           * To invoke the sms-invitation action we need to update the user record to have a phone number.
           *
           * This requires invoking editPatientDetails with all the required properties.
           * This implicitly creates a requirement that clinicianId must be set before SMS invitations can be sent
           */
          if (record.raw.assignedClinicians.length === 0) {
            throw new Error(
              'Please assign a clinician to enable the SMS invite'
            );
          }

          /**
           * A new number was provided
           * Update the user record
           */
          if (extraData.type === 'new_number') {
            await clinicActionEditPatientDetails({
              patientId: record.raw.id,
              firstName: record.firstName,
              lastName: record.lastName,
              email: record.email,
              /**
               * Convert empty strings to null
               */
              phoneNumber: stringOrNull(extraData.phoneNumber),
              clinicianId: record.raw.assignedClinicians[0].id,
              clinicId: props.clinicId
            });
          }

          // Resend the invitation link
          return clinicActionResendInvitation({
            invitationId: record.raw.id,
            resendType: 'sms'
          });
        },
        onSuccess: {
          blockOnDone: false,
          timeout: 1000
        }
      },
      {
        name: 'Edit',
        initialState: () => ActionState.NEUTRAL,
        render: _props => (
          <TagEditDetails
            {..._props}
            userAddQuestions={patientUserAddQuestions}
            initialValues={{
              firstName: _props.record.firstName,
              lastName: _props.record.lastName,
              email: _props.record.email,
              phoneNumber: _props.record.raw.phoneNumber,
              clinicianId: (_props.record.raw as any).assignedClinicians
                .map((_: any) => `${_.id}`)
                .join(', ')
            }}
          />
        ),
        onClick: (p, extraData) => {
          if (!p.raw.id) {
            throw new Error('ID is undefined. Please try reloading the page');
          }

          return clinicActionEditPatientDetails({
            patientId: p.raw.id,
            firstName: extraData.firstName,
            lastName: extraData.lastName,
            email: extraData.email,
            /**
             * Convert empty strings to null
             */
            phoneNumber: stringOrNull(extraData.phoneNumber),
            clinicianId: extraData.clinicianId,
            clinicId: props.clinicId
          });
        },
        onSuccess: {
          blockOnDone: false,
          timeout: 1000
        }
      },

      {
        name: 'Copy invite link',
        shouldShow: p => p.invitePending,
        initialState: () => ActionState.NEUTRAL,
        onClick: async p => {
          if (!p.raw.id) {
            throw new Error('ID is undefined. Please try reloading the page');
          }
          const link = await clinicActionGetInvitationLink({
            invitationId: p.raw.id
          });
          console.log(link);
          navigator.clipboard.writeText(link.data);
          return link;
        }
      }
    ],
    [props.clinicId, patientUserAddQuestions]
  );

  /**
   * Extra columns for the table
   */
  const extraColumns: UserListProps<PatientRecord>['extraColumns'] = [
    {
      title: 'Clinician',
      dataIndex: 'clinician',
      render: (_value, record) => {
        const rawRecord = record.raw;
        if (rawRecord.assignedClinicians === undefined) {
          console.warn(
            `[extraColumns - clinician] Missing assignedClinicians property`
          );
          return '';
        }
        return rawRecord.assignedClinicians.map(_ => _.name).join(', ');
      }
    }
  ];

  return (
    <UserTable<PatientRecord>
      typeUnit="Patient"
      apiAddUser={apiAddUser}
      api={api}
      clinicId={props.clinicId}
      extraColumns={extraColumns}
      actions={actions}
      userAddQuestions={patientUserAddQuestions}
    />
  );
}

// eslint-disable-next-line
function InputWithAddonBefore(props: Omit<InputProps, 'addonBefore'>) {
  return (
    <Input
      {...props}
      addonBefore={
        <Select>
          <Select.Option value="option1">Option 1</Select.Option>
        </Select>
      }
    />
  );
}
