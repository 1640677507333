import { Message } from '../types';

export const questions: Message[] = [
  {
    id: '0',
    role: 'user',
    content: 'Example question 1'
  },
  {
    id: '1',
    role: 'user',
    content: 'Example question 2'
  },
  {
    id: '2',
    role: 'user',
    content: 'Example question 3'
  }
];
