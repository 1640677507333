import { Card, CardProps } from 'antd';
import clsx from 'clsx';
import { CSSProperties } from 'react';
import { grey } from 'styles/colors';

const baseStyle: CSSProperties = {
  background: 'transparent',
  border: `1px solid ${grey.medium}`
};

export function OutlinedCard(props: CardProps) {
  return (
    <Card
      {...props}
      className={clsx('clickable', props.className)}
      style={{
        ...baseStyle,
        ...props.style
      }}
    />
  );
}
