import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputProps, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';

export interface OutlinedInputProps
  extends Pick<InputProps, 'placeholder' | 'disabled'> {
  onSubmit?: (value: string) => void;
}

export function OutlinedInput(props: OutlinedInputProps) {
  const [form] = useForm();
  function submit(values: { value: string }) {
    props.onSubmit?.(values.value);
    form.resetFields();
  }
  return (
    <Form form={form} onFinish={submit}>
      <Form.Item name="value">
        <Space.Compact style={{ width: '100%' }}>
          <Input
            placeholder={props.placeholder}
            disabled={props.disabled}
            style={{
              borderColor: '#230056',
              background: 'transparent'
            }}
            type="text"
            allowClear
            suffix={
              <Button
                htmlType="submit"
                style={{ border: 'transparent', background: 'transparent' }}
                icon={<SearchOutlined />}
              />
            }
          />
        </Space.Compact>
      </Form.Item>
    </Form>
  );
}
