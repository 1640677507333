import { SymptomCardData } from '../../features/patient/dashboard/symptom-card';

import { TestRecord } from 'api/impact/types';
import { PatientInfoState } from 'components/patient-info/patient-info-slice-types';
import {
  tScoreCategory,
  tScoreMapping,
  TScores
} from 'documents/promisTScores';
type RequiredVisualisationData = Pick<
  PatientInfoState,
  'dhi' | 'promis' | 'impact'
>;
type SymptomCardDataGenerator = (
  args: RequiredVisualisationData
) => SymptomCardData[];
type StaticBrainFunctionVisualisation = {
  type: 'static';
  /**
   * The label of the visualisation
   */
  label: string;
  /**
   * Callback to fetch the data
   */
  getValues: (args: RequiredVisualisationData) => SymptomCardData[];
  scale: number[];
  isRawScore?: boolean;
};
type DynamicBrainFunctionVisualisation = {
  type: 'dynamic';
  label: string;
  getVisualisation: (
    args: RequiredVisualisationData
  ) => Pick<
    StaticBrainFunctionVisualisation,
    'getValues' | 'isRawScore' | 'scale'
  >;
};
export type BrainFunctionVisualisation =
  | StaticBrainFunctionVisualisation
  | DynamicBrainFunctionVisualisation;

interface BrainFunctionCategory {
  /**
   * The category title
   */
  title: string;
  /**
   * The visualisations in the category
   */
  visualisations: BrainFunctionVisualisation[];
}

/**
 * Curried getPromisTotalScore
 * @param subsections
 * @returns
 */
const promisCurry =
  (subsections: (keyof typeof tScoreMapping)[], category: tScoreCategory) =>
  (args: Parameters<SymptomCardDataGenerator>[0]): SymptomCardData[] =>
    args.promis.map(value => ({
      date: value.date,
      value: subsections
        .map(subsection =>
          value.data[subsection] === null
            ? 0
            : TScores.findTScore(value.data[subsection], category, 0)
        )
        .reduce((acc, cur) => acc + cur, 0)
    }));

/**
 * Helper method to get a specified score from a list of
 * ImPACT `TestRecord`'s transformed into `Inventory`'s
 */
const impactCurry =
  (
    query: keyof Pick<
      TestRecord,
      | 'userMemoryCompositeScoreVerbal'
      | 'userMemoryCompositeScoreVisual'
      | 'userImpulseControlCompositeScore'
      | 'userReactionTimeCompositeScore'
      | 'userVisualMotorCompositeScore'
    >
  ) =>
  (args: Parameters<SymptomCardDataGenerator>[0]): SymptomCardData[] =>
    args.impact.map(test => ({
      date: test.date,
      value: test.data[query]
    }));

/**
 * Evaluate a visualisation config and generate a static config if applicable
 *
 * @param visualisation
 * @param visualisationData
 * @returns
 */
export function evaluateBrainFunctionVisualisation(
  visualisation: BrainFunctionVisualisation,
  visualisationData: RequiredVisualisationData
): StaticBrainFunctionVisualisation {
  if (visualisation.type === 'static') return visualisation;
  const { getValues, scale, isRawScore } =
    visualisation.getVisualisation(visualisationData);
  return {
    label: visualisation.label,
    type: 'static',
    getValues,
    scale,
    isRawScore
  };
}

export const brainFunctionCategories: BrainFunctionCategory[] = [
  {
    title: 'Balance and movement',
    visualisations: [
      {
        type: 'static',
        label: 'Vestibular/Balance',
        /**
         * Total score of all the DHI questions
         * There are 25 questions and the scale is 0-2.
         * The total is multiplied by 2 to bring the total to 100
         */
        getValues: ({ dhi }) =>
          dhi.map(val => ({ date: val.date, value: val.data['Total'] })),
        scale: [0, 15, 25, 100],
        isRawScore: true
      },
      {
        type: 'static',
        label: 'Physical Function',
        /**
         * T-score of the physical function questions in PROMIS - the first 8 questions
         * The physical mobility section includes "physical function" and "health"
         */
        getValues: param => {
          return promisCurry(
            ['Physical Function'],
            tScoreCategory.PhysicalFunction
          )(param);
        },
        scale: [100, 60, 40, 0]
      }
    ]
  },
  {
    title: 'Pain and sleep',
    visualisations: [
      {
        type: 'static',
        label: 'Pain',
        /**
         * T-score of the pain questions in PROMIS - 7th set of question (9 questions)
         */
        getValues: promisCurry(
          ['Pain Interferance'],
          tScoreCategory.PainInterferance
        ),
        scale: [0, 40, 60, 100]
      },
      {
        type: 'static',
        label: 'Sleep',
        /**
         * T-score of the sleep disturbance questions in PROMIS - 5th set of question (8 questions)
         */
        getValues: promisCurry(
          ['Sleep Disturbance'],
          tScoreCategory.SleepDisturbance
        ),
        scale: [0, 40, 60, 100]
      }
    ]
  },
  {
    title: 'Cognition and memory',
    visualisations: [
      {
        type: 'static',
        label: 'Attention/Memory',
        /**
         * ImPact raw score
         */
        getValues: impactCurry('userMemoryCompositeScoreVerbal'),
        scale: [100, 95, 85, 0],
        isRawScore: true
      },
      {
        type: 'static',
        label: 'Cognitive Function',
        /**
         * T-score of the cognitive function abilities questions in PROMIS - 9th set of question (8 questions)
         */
        getValues: promisCurry(
          [
            'Cognitive Function Abilities',
            /**
             * Temporarily include the `Cognitive Func. Ab.` key for legacy patients
             *
             * https://trello.com/c/O88YnB44
             */
            'Cognitive Func. Ab.' as any
          ],
          tScoreCategory.CognitiveFunctionAbilities
        ),
        scale: [100, 60, 40, 0]
      },
      {
        type: 'dynamic',
        label: 'Vision/Oculomotor',
        getVisualisation: visionOculomotorDial
      }
    ]
  },
  {
    title: 'Mood and social function',
    visualisations: [
      {
        type: 'static',
        label: 'Fatigue',
        /**
         * T-score of the fatigue questions in PROMIS - 4th set of question (8 questions)
         */
        getValues: promisCurry(['Fatigue'], tScoreCategory.Fatigue),
        scale: [0, 40, 60, 100]
      },
      {
        type: 'static',
        label: 'Anxiety',
        /**
         * T-score of the anxiety questions in PROMIS - 2nd set of question (8 questions)
         */
        getValues: promisCurry(['Anxiety'], tScoreCategory.Anxiety),
        scale: [0, 40, 60, 100]
      },
      {
        type: 'static',
        label: 'Depression',
        /**
         * T-score of the depression questions in PROMIS - 3rd set of question (8 questions)
         */
        getValues: promisCurry(['Depression'], tScoreCategory.Depression),
        scale: [0, 40, 60, 100]
      },
      {
        type: 'static',
        label: 'Social Function',
        /**
         * T-score of the Ability to Participate in Social Roles and Activities questions in PROMIS - 6th set of question (8 questions)
         */
        getValues: promisCurry(
          ['Abilities to participate in social roles and activities'],
          tScoreCategory.AbilityToParticipateInSocialRolesAndActivities
        ),
        scale: [100, 60, 40, 0]
      }
    ]
  }
];

/**
 * [task-387](https://trello.com/c/tyWhBTP0/387)
 * Define "Vision/Oculomotor" dial to replace "Reaction Time" and "Visual Motor Processing Speed".
 *
 *
 */
function visionOculomotorDial(
  args: RequiredVisualisationData
): ReturnType<DynamicBrainFunctionVisualisation['getVisualisation']> {
  const visualisations: Record<
    'rt' | 'vmps',
    StaticBrainFunctionVisualisation
  > = {
    rt: {
      type: 'static',
      label: 'Reaction Time',
      /**
       * ImPact raw score
       */
      getValues: impactCurry('userReactionTimeCompositeScore'),
      scale: [0, 0.6, 0.7, 1],
      isRawScore: true
    },
    vmps: {
      type: 'static',
      label: 'Visual Motor Processing Speed',
      /**
       * ImPact raw score
       */
      getValues: (..._args: [RequiredVisualisationData]) =>
        /* 2024-11-25 Visual Motor Processing Speed should be rounded to the nearest number with one decimal */
        impactCurry('userVisualMotorCompositeScore')(..._args).map(val => ({
          ...val,
          value: Number(val.value.toFixed(1))
        })),
      scale: [0, 37, 42, 100],
      isRawScore: true
    }
  };

  const rt = visualisations['rt'];
  const vmps = visualisations['vmps'];

  const MAX_RT = Math.max(...rt.scale);
  const MAX_VMPS = Math.max(...vmps.scale);
  const vmpsCompVal = vmps.getValues(args).at(0)?.value ?? 0;
  const rtCompVal = rt.getValues(args).at(0)?.value ?? 0;
  const compare = vmpsCompVal / MAX_VMPS > rtCompVal / MAX_RT;

  if (compare) return vmps;
  return rt;
}

/**
 * Extract the visualisations from the categories list
 */
export const brainFunctionVisualisations = brainFunctionCategories.flatMap(
  category => category.visualisations
);
