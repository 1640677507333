import './Navigation.scss';

import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { Layout, Skeleton } from 'antd';
import { desktopMinWidth } from 'constants/media-queries';

import { RootState } from 'app/rootReducer';
import { Role } from 'features/auth/types';

import { ClinicianMenu } from './menu-clinician';
import { PatientMenu } from './menu-patient';
import { toggleMenuCollapse } from './navigationSlice';
import { SideMenuFooter } from './menu-side-footer';

const { Sider } = Layout;

import ConcussionLogo from 'img/sidebar-logo.svg';
import { User } from 'features/auth';

export function Navigation() {
  const user = useSelector((state: RootState) => state.auth.user);
  const collapsed = useSelector(
    (state: RootState) => state.uiPref.menuCollapsed
  );
  const isLoading = useSelector(
    (state: RootState) => state.auth.isUserDataLoading
  );
  const isDesktop = useMediaQuery(desktopMinWidth);
  if (!user || user.needsPassword) return <></>;

  return (
    <Sider
      trigger={null}
      theme="light"
      collapsedWidth={isDesktop ? 80 : 80}
      width={236}
      collapsible
      collapsed={collapsed}
      className="crx-sider"
    >
      <h2 className="logo">
        <img src={ConcussionLogo} />
      </h2>

      <SideMenu user={user} isLoading={isLoading} />
    </Sider>
  );
}

export function SideMenu(props: { user: User; isLoading: boolean }) {
  const collapsed = useSelector(
    (state: RootState) => state.uiPref.menuCollapsed
  );

  const dispatch = useDispatch();
  return (
    <div className="rx-sidemenu">
      <div>
        {[Role.Clinician, Role.ClinicAdmin].includes(props.user.role) && (
          <ClinicianMenu />
        )}
        {props.user.role === Role.Patient && <PatientMenu />}
      </div>
      {props.isLoading && <Skeleton />}

      <div>
        <SideMenuFooter
          collapsed={collapsed}
          onClick={() => {
            dispatch(toggleMenuCollapse());
          }}
          user={props.user}
        />
      </div>
    </div>
  );
}
