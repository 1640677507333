import { useEffect } from 'react';
import {
  QuestionnaireControlSliceReducer,
  questionnaireInfoInitialLoad,
  initialState
} from './questionnaire-control-flow-slice';
import { useReducer } from 'hooks/useReducer';
import { useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import {
  setFulfilled,
  setRequired
} from 'features/navigation/rx-progress-slice';
import { ImpactQuestionnaire } from 'features/patient/impact-questionnaire';
import {
  IntroPromisA,
  IntroCgs,
  IntroPcs,
  IntroDhi
} from './questionnaire-intros';
import { Skeleton, Typography } from 'antd';
import { Redirect, useLocation } from 'react-router-dom';
import { PatientRoutes } from 'routes';
import { getPatientLinkUrl } from 'components/route-switch/route-switch-patient/patient-route-helpers';
import { Collection } from 'documents/document';
import { isMobile } from 'react-device-detect';
import { CrxMobileDeviceAssessmentBlocked } from '../assessment-intro';

/**
  components involved:
  assessment/Intro
  above individual intros
  patientPromis, patientDhi, patientCgs, patientPcs

  states:
  -

  interface should just be normal react with children
  React.FC<PropsWithChildren>;

  - return false(?) over null?
  https://medium.com/@davidkelley87/stop-using-return-null-in-react-a2ebf08fc9cd#:~:text=If%20a%20component%20returns%20null,that%20need%20to%20be%20updated.


  QuestionnaireControlSlice
  - keeps track of the 4 assessment tests (latest 30 days) and progress bar;
*/
export function QuestionnaireControlFlow() {
  const [
    { required, fulfilled, done, allDone, isLoading, assessmentData },
    dispatch
  ] = useReducer(QuestionnaireControlSliceReducer, initialState);
  const user = useSelector((state: RootState) => state.auth.user);

  const progressBarDispatch = useDispatch();
  const reduxDispatch = useDispatch();
  const locationState = useLocation<
    { fromQuestionnaire: boolean; questionnaireName: string } | undefined
  >();

  useEffect(() => {
    if (!user) return;
    dispatch(
      questionnaireInfoInitialLoad({
        patientId: user.uid,
        rootPath: Collection.PendingPatientAssessments
      })
    );
  }, [user]);

  useEffect(() => {
    progressBarDispatch(setFulfilled(fulfilled));
    progressBarDispatch(setRequired(required));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfilled, required]);

  useEffect(() => {
    if (allDone && !isLoading) {
      reduxDispatch(push('/'));
    }
  }, [allDone, isLoading, reduxDispatch]);

  if (!user || allDone || !locationState) return <Skeleton active />;
  if (assessmentData === null) return <Redirect to={'/'} />;

  /**
   * Show an error message that assessments cannot be completed on mobile devices
   */
  if (isMobile) {
    return <CrxMobileDeviceAssessmentBlocked />;
  }

  if (
    locationState.state?.fromQuestionnaire &&
    locationState.state?.questionnaireName === 'promis'
  ) {
    return (
      <Redirect
        to={getPatientLinkUrl(PatientRoutes.CompletionPromis, user.uid)}
      />
    );
  }
  /**
   * If the last navigation was from a non-promis questionnaire, there is one more test, and ImPACT is not done,
   * Then redirect to the completion route to give the user a break
   */
  if (
    locationState.state?.fromQuestionnaire &&
    fulfilled + 1 === required &&
    done['impact'] === false
  ) {
    return (
      <Redirect to={getPatientLinkUrl(PatientRoutes.Completion, user.uid)} />
    );
  }

  if (done['promis'] === false) {
    return <IntroPromisA userId={user.uid} />;
  } else if (done['cgs'] === false) {
    return <IntroCgs userId={user.uid} />;
  } else if (done['pcs'] === false) {
    return <IntroPcs userId={user.uid} />;
  } else if (done['dhi'] === false) {
    return <IntroDhi userId={user.uid} />;
  } else if (done['impact'] === false) {
    return <ImpactQuestionnaire userId={user.uid} />;
  } else {
    return (
      <>
        <Typography.Title>All tests have been completed</Typography.Title>
        <Typography>This page will reload automatically.</Typography>
      </>
    );
  }
}
