import {
  addClinician,
  clinicActionEditClinicianDetails,
  clinicActionGetInvitationLink,
  clinicActionResendInvitation,
  listClinicians,
  ListUserAPIOptions
} from 'api/firebase/firebase-api';
import { Clinician } from 'documents/clinician';
import { isNil, omitBy } from 'lodash';
import { ActionState } from '../action-tag/action-tag-component';
import { TagEditDetails } from '../action-tag/tag-edit-details';
import { BASE_USER_ADD_QUESTIONS } from '../../types/form-questions';
import UserTable from './user-table';
import { UserListProps } from './user-table-types';
const api: UserListProps<Clinician>['api'] = ({
  clinicId,
  lastUserId,
  lastPendingUserId
}) =>
  listClinicians(
    omitBy(
      {
        clinicId,
        pendingUsersLimit: 10,
        usersLimit: 10,
        pendingUsersLastDocId: lastPendingUserId,
        usersLastDocId: lastUserId
      },
      isNil
    ) as any as ListUserAPIOptions
  );

export function ClinicianTable(props: { clinicId: string }) {
  return (
    <UserTable<Clinician>
      typeUnit="Clinician"
      apiAddUser={(user, clinicId) => addClinician({ ...user, clinicId })}
      api={api}
      clinicId={props.clinicId}
      actions={[
        {
          name: 'Edit',
          initialState: () => ActionState.NEUTRAL,
          render: _props => (
            <TagEditDetails
              {..._props}
              userAddQuestions={BASE_USER_ADD_QUESTIONS}
              initialValues={{
                firstName: _props.record.firstName,
                lastName: _props.record.lastName,
                email: _props.record.email
              }}
            />
          ),
          onClick: p => {
            if (!p.raw.id) {
              throw new Error('ID is undefined. Please try reloading the page');
            }

            return clinicActionEditClinicianDetails({
              uid: p.raw.id,
              firstName: p.firstName,
              lastName: p.lastName,
              email: p.email
            });
          }
        },
        {
          name: 'Resend invitation',
          shouldShow: p => p.invitePending,
          initialState: () => ActionState.NEUTRAL,
          onClick: p => {
            if (!p.raw.id)
              throw new Error('ID is undefined. Please try reloading the page');
            return clinicActionResendInvitation({
              invitationId: p.raw.id,
              resendType: 'email'
            });
          }
        },
        {
          name: 'Copy invite link',
          shouldShow: p => p.invitePending,
          initialState: () => ActionState.NEUTRAL,
          onClick: async p => {
            if (!p.raw.id) {
              throw new Error('ID is undefined. Please try reloading the page');
            }
            const link = await clinicActionGetInvitationLink({
              invitationId: p.raw.id
            });
            console.log(link);
            navigator.clipboard.writeText(link.data);
            return link;
          }
        }
      ]}
      userAddQuestions={BASE_USER_ADD_QUESTIONS}
    />
  );
}
