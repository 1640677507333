import { z } from 'zod';
import { FormQuestion } from '../../types/form-questions';
import { ActionTagSettings } from '../action-tag/action-tag-component';
import { ColumnsType } from 'antd/lib/table';

export interface BaseTableUserRecord {
  firstName: string;
  lastName: string;
  email: string;
}

export const baseTableUserRecordSchema: z.ZodType<BaseTableUserRecord> =
  z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email()
  });

/**
 * Expected minimum properties for APIs
 */
export interface BaseTableUserAPIResponse {
  displayName: string;
  email: string;
  /**
   * WARN: ID is marked optional here but required in `baseTableUserAPIResponseSchema`.
   * This is intentional because `id` is only missing for user records that have not been added to the database
   */
  id?: string;
}

export const baseTableUserAPIResponseSchema: z.ZodType<BaseTableUserAPIResponse> =
  z.object({
    displayName: z.string(),
    email: z.string(),
    id: z.string()
  });

export type TableRow<T> = BaseTableUserRecord & {
  invitePending: boolean;
  key: string;
  raw: T;
};

export interface ListUserReturnResult<T extends BaseTableUserAPIResponse> {
  inClinic: T[];
  pendingInClinic: T[];
}

export interface UserListProps<T extends BaseTableUserAPIResponse> {
  clinicId: string;
  api: (options: {
    clinicId: string;
    lastUserId?: string;
    lastPendingUserId?: string;
  }) => Promise<{ data: ListUserReturnResult<T> }>;
  apiAddUser: (
    userData: BaseTableUserRecord,
    clinicId: string
  ) => Promise<void | any>;
  actions?: ActionTagSettings<T>[];
  typeUnit: string;
  userAddQuestions: FormQuestion[];
  extraColumns?: ColumnsType<TableRow<T>>;
}
