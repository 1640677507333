import { Flex, Space, Typography } from 'antd';
import { Title } from 'components/mvp-typography';
import { PatientInfoContext } from 'components/patient-info/hook-context-patientinfo';
import { ConcussionComplexity } from 'documents/concussion-complexity';
import moment from 'moment';
import { useContext, useState } from 'react';
import { getFirstName } from 'utils/string/normalise';
import { StyledConcussionComplexity } from '../../dashboard/components/styled-concussion-complexity';
import { ConcussionComplexityDiagramWrapper } from './concussion-complexity-diagram';
import { ToggleWithLabel } from 'features/patient/dashboard/symptom-card/symptom-card-2';

const averageRecoveryTimes = {
  [ConcussionComplexity.Minimal]: '1-3 months',
  [ConcussionComplexity.Mild]: '3-6 months',
  [ConcussionComplexity.Moderate]: '6-9 months',
  [ConcussionComplexity.High]: '9-12 months',
  [ConcussionComplexity.Extreme]: '12-18 months'
};

export function SubtypeCard() {
  const {
    patientInfo: { concussion, patient }
  } = useContext(PatientInfoContext);

  const [showRecoveryTime, setShowRecoveryTime] = useState(false);

  const firstName = getFirstName(patient.displayName ?? '');
  const description = `We believe that concussion is best understood in terms of its
  complexity rather than independent silos of system specific
  dysfunction. The complexity is based on the assessment ${firstName}
  has done. Every time the assessment is completed, the complexity will be
  updated.`;

  return (
    <>
      <div>
        <Title level={2}>{`${firstName}'s Complexity Subtype`}</Title>
        <Typography.Paragraph style={{ fontFamily: 'Comfortaa' }}>
          {description}
        </Typography.Paragraph>
      </div>
      <Flex justify={'space-evenly'}>
        <Flex
          vertical
          justify={'center'}
          align={'center'}
          style={{
            textAlign: 'center'
          }}
        >
          <Typography.Text style={{ color: '#767679', marginBottom: 5 }}>
            <small>
              Evaluated on{' '}
              {moment(concussion.evaluatedOn.toISOString()).format(
                'MMM DD, YYYY'
              )}
            </small>
          </Typography.Text>

          <StyledConcussionComplexity
            complexity={concussion.subtype}
            style={{ padding: '1rem' }}
            textLevel={2}
          />
          <Space direction="vertical" size={0} style={{ marginTop: '1rem' }}>
          {
            /**
             * 2025-03-31
             * The recovery time toggle should not be displayed in the demo environment for now.
             */
            process.env.REACT_APP_CONCUSSIONRX_DEPLOYMENT !== 'demo' && (
              <ToggleWithLabel
                checked={showRecoveryTime}
                setChecked={setShowRecoveryTime}
                suffix="estimated recovery time"
              />
            )
            }
            {showRecoveryTime && (
              <>
                <Typography.Text
                  style={{
                    fontFamily: 'Comfortaa',
                    fontWeight: 700,
                    color: '#A366FC'
                  }}
                >
                  <b>Average recovery time:</b>
                </Typography.Text>
                <Typography.Text
                  style={{
                    fontWeight: 700,
                    color: '#A366FC'
                  }}
                >
                  <b>{averageRecoveryTimes[concussion.subtype]}</b>
                </Typography.Text>
              </>
            )}
          </Space>
        </Flex>
        <ConcussionComplexityDiagramWrapper concussion={concussion} />
      </Flex>
    </>
  );
}
