import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { PatientRoutes } from 'routes';
import { getPatientLinkUrl } from 'components/route-switch/route-switch-patient/patient-route-helpers';
import { QuestionnaireProps, QuestionnaireIntro } from './questionnaire-intro';

import DizzinessLogo from 'img/dhi-questionnaire.svg';

import PcsLogo from 'img/pcs-questionnaire.svg';
import PromisLogo from 'img/promis-questionnaire.svg';
import CgsLogo from 'img/symptoms-questionnaire.svg';
import { QuestionnaireDataProps } from './types';
import { SignOutButton } from 'components/sign-out-button';

// promis A
export const promisDataA: QuestionnaireDataProps = {
  imgLogo: PromisLogo,
  header: {
    title: '1st Questionnaire',
    headerButtonOverride: () => <SignOutButton rounded />
  },
  subtitle: '1st questionnaire'.toUpperCase(),
  title: 'Assessing your function',
  description:
    'This questionnaire is to identify difficulties that you may be experiencing in a number of functional areas. You’ll have an option for a break halfway, but we recommend you finish in one go.',
  duration: '8 mins'
};
// promis B
export const promisDataB: QuestionnaireDataProps = {
  imgLogo: PromisLogo,
  header: { title: 'Promis Questionnaire Part B' },
  subtitle: '2th questionnaire'.toUpperCase(),
  title: 'Checking your promis',
  description: 'This questionnaire is about promis testing B',
  duration: '5 mins'
};
// cgs
export const cgsData: QuestionnaireDataProps = {
  imgLogo: CgsLogo,
  header: { title: '2nd Questionnaire' },
  subtitle: '2nd questionnaire'.toUpperCase(),
  title: 'Evaluate your symptoms',
  description:
    'This questionnaire is to evaluate your concussion symptoms. After reading each symptom, please choose the number that best describes the way you have been feeling today.',
  duration: '3 mins'
};
// pcs post concussion syndrome
export const pcsData: QuestionnaireDataProps = {
  imgLogo: PcsLogo,
  header: { title: '3rd Questionnaire' },
  subtitle: '3rd questionnaire'.toUpperCase(),
  title: 'Understanding your pain',
  description:
    'This questionnaire is to understand how you are feeling when in pain. Using the following scale, please indicate the degree to which you have these thoughts and feelings when you are experiencing pain.',
  duration: '4 mins'
};

// dhi
export const dhiData: QuestionnaireDataProps = {
  imgLogo: DizzinessLogo,
  header: { title: '4th Questionnaire' },
  subtitle: '4th questionnaire'.toUpperCase(),
  title: 'Assessing your dizziness',
  description:
    'The purpose of this questionnaire is to identify difficulties that you may be experiencing because of your dizziness. Answer each question only as it pertains to your dizziness problem.',
  duration: '5 mins'
};

export interface QuestionnaireIntroProps extends QuestionnaireProps {
  userId: string;
}

export function IntroPromisA({
  data = promisDataA,
  ...props
}: QuestionnaireIntroProps) {
  const dispatch = useDispatch();

  return (
    <QuestionnaireIntro
      data={data}
      startQuestionnaire={() => {
        dispatch(
          push(getPatientLinkUrl(PatientRoutes.PatientPromis, props.userId))
        );
      }}
    />
  );
}
/**
 *
 * @deprecated
 * @param param0
 * @returns
 */

export function IntroPromisB({
  data = promisDataB,
  ...props
}: QuestionnaireIntroProps) {
  const dispatch = useDispatch();
  return (
    <QuestionnaireIntro
      data={data}
      startQuestionnaire={() => {
        dispatch(
          push(getPatientLinkUrl(PatientRoutes.PatientPromis, props.userId))
        );
      }}
    />
  );
}
export function IntroCgs({
  data = cgsData,
  ...props
}: QuestionnaireIntroProps) {
  const dispatch = useDispatch();
  return (
    <QuestionnaireIntro
      data={data}
      startQuestionnaire={() => {
        dispatch(
          push(getPatientLinkUrl(PatientRoutes.PatientCgs, props.userId))
        );
      }}
    />
  );
}
export function IntroPcs({
  data = pcsData,
  ...props
}: QuestionnaireIntroProps) {
  const dispatch = useDispatch();
  return (
    <QuestionnaireIntro
      data={data}
      startQuestionnaire={() => {
        dispatch(
          push(getPatientLinkUrl(PatientRoutes.PatientPcs, props.userId))
        );
      }}
    />
  );
}
export function IntroDhi({
  data = dhiData,
  ...props
}: QuestionnaireIntroProps) {
  const dispatch = useDispatch();
  return (
    <QuestionnaireIntro
      data={data}
      startQuestionnaire={() => {
        dispatch(
          push(getPatientLinkUrl(PatientRoutes.PatientDhi, props.userId))
        );
      }}
    />
  );
}
