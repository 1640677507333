import { Flex, List, Spin } from 'antd';
import { clinicianQuery } from 'api/firebase/apis/clinician-query';
import { useAppSelector } from 'app/rootReducer';
import { Subtitle } from 'components/mvp-typography';
import { PrintableRootPage } from 'components/page/page-2';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import { useEffect, useState } from 'react';
import { theme } from 'styles/colors';
import { resolveError } from 'utils/formatters/error/resolve-error';
import { v4 } from 'uuid';
import { Gradient } from './components/linear-gradient';
import { OutlinedCard } from './components/outlined-card';
import {
  QuestionResponseGroup,
  QuestionResponseSet
} from './components/question-response-set';
import { questions } from './data/sample-questions';
import { QuestionInput } from './components/question-input';

enum ActionState {
  READY,
  PROCESSING,
  ERROR
}

interface ClinicianQueryPageState {
  state: ActionState;
  history: QuestionResponseGroup[];
  error: Error | null;
}

export function ClinicianQueryPage() {
  const backgroundColor = useAppSelector(state => state.uiPref.backgroundColor);

  const [state, setState] = useState<ClinicianQueryPageState>({
    state: ActionState.READY,
    history: new Array(0).fill(0).map(() => ({
      id: v4(),
      question: {
        message: { id: v4(), role: 'user', content: `sample-message-${v4()}` }
      },
      response: {
        message: {
          id: v4(),
          role: 'assistant',
          content: `sample-response-${v4()}`
        },
        references: [
          {
            id: `pmid-${v4()}`,
            title: `Sample title ${v4()}`,
            abstract: `Sample abstract ${v4()}`,
            publicationTypes: ['Review', 'Review']
          }
        ]
      }
    })),
    error: null
  });

  useEffect(() => {
    if (state.state === ActionState.PROCESSING) {
      // Scroll to the top of the page
      window.scrollTo(0, 0);
    }
  }, [state.state]);

  console.log('State', state.history);
  async function askQuestion(question: string) {
    try {
      const questionResponseGroup: QuestionResponseGroup = {
        id: v4(),
        question: {
          message: { role: 'user', content: question, id: v4() }
        }
      };
      setState(prev => ({
        ...prev,
        history: [questionResponseGroup, ...prev.history],
        state: ActionState.PROCESSING
      }));

      const response = await clinicianQuery({ question });

      setState(prev => ({
        ...prev,
        history: [
          {
            ...questionResponseGroup,
            response: {
              message: {
                role: 'assistant',
                id: v4(),
                content: response.data.chatMessage.content
              },
              references: response.data.references
            }
          },
          ...prev.history.filter(_ => _.id !== questionResponseGroup.id)
        ],
        state: ActionState.READY
      }));
    } catch (e) {
      setState(prev => ({
        ...prev,
        state: ActionState.ERROR,
        error: resolveError(e)
      }));
    }
  }

  function submit(value: string) {
    // Ask the question
    askQuestion(value);
  }

  return (
    <PrintableRootPage title="ConcussionRx Companion" hideTitle>
      <Flex
        vertical
        gap={'large'}
        style={{
          position: 'relative',
          width: '100%',
          height: '100%'
        }}
      >
        <Flex vertical align="center">
          <Subtitle color={theme.primary} uppercase>
            ConcussionRx companion
          </Subtitle>
        </Flex>

        <Flex vertical align="center" style={{ height: '100%', width: '100%' }}>
          <div
            style={{
              position: 'sticky',
              bottom: 0,
              display: 'flex',
              width: '100%',
              height: '8rem',
              flexDirection: 'column'
            }}
          >
            <Gradient
              from={backgroundColor + '00'}
              to={backgroundColor}
              style={{
                height: '2.5rem'
              }}
            />
            <Flex
              vertical
              align="center"
              style={{
                backgroundColor,
                height: '100%',
                width: '100%'
              }}
            >
              <div
                style={{
                  width: '80%'
                }}
              >
                <QuestionInput
                  onSubmit={submit}
                  disabled={state.state === ActionState.PROCESSING}
                />
              </div>
            </Flex>

            <Flex
              align="center"
              justify="center"
              style={{
                marginBottom: '1rem'
              }}
            >
              {state.state === ActionState.PROCESSING && (
                <div>
                  <Spin percent={0} />
                </div>
              )}
              {state.state === ActionState.ERROR && state.error && (
                <ErrorDisplay
                  error={state.error}
                  title="Something went wrong"
                  description=""
                />
              )}
            </Flex>
          </div>

          {/* Main content */}
          <div style={{ width: '80%', flex: 1 }}>
            <Flex vertical gap={'large'}>
              {
                /**
                 * Chat area
                 *
                 * If there is no chat history then show example questions
                 */
                state.history.length === 0 ? (
                  questions.map(question => (
                    <OutlinedCard
                      key={`sample-question-${question.id}`}
                      onClick={() => {
                        askQuestion(question.content);
                      }}
                    >
                      {question.content}
                    </OutlinedCard>
                  ))
                ) : (
                  <List
                    dataSource={state.history}
                    renderItem={item => (
                      <List.Item>
                        <QuestionResponseSet responseGroup={item} />
                      </List.Item>
                    )}
                  />
                )
              }
            </Flex>
          </div>
        </Flex>
      </Flex>
    </PrintableRootPage>
  );
}
