import { auth } from 'api/firebase/firebase-api';
import { AppThunk } from 'app/store';
import { push } from 'connected-react-router';
import firebase from 'firebase/compat/app';
import { PatientRoutePrefix, PatientRoutes, Routes } from 'routes';
import { authSlice, AuthSliceActions, User } from './authSlice';
import { Role } from './types';
import { resolveError } from 'utils/formatters/error/resolve-error';

export const signInProcess =
  (email: string, password: string): AppThunk =>
  async dispatch => {
    dispatch(AuthSliceActions.setError(''));
    // verification of email should be handled by restoreAuth
    auth
      .signInWithEmailAndPassword(email, password)
      .then(userCredential => {
        const user = userCredential.user;
        if (!user) {
          dispatch(
            AuthSliceActions.setError(
              'Internal server error. Please contact us'
            )
          );
          return;
        }
      })
      .catch(_error => {
        const error = resolveError(_error);
        console.log('signInProcess error: ', error);
        dispatch(
          AuthSliceActions.setError(
            `Unable to sign in. Possible reasons are an invalid email or password. Error message: ${error.message}`
          )
        );
      });
  };

export const signOut =
  (auth: firebase.auth.Auth, user: Pick<User, 'role'> | null): AppThunk =>
  async dispatch => {
    await auth.signOut();
    dispatch(authSlice.actions.signOut());
    if (!user) return;
    if ([Role.ClinicAdmin, Role.Clinician].includes(user.role)) {
      dispatch(push(Routes.ClinicianSignIn));
    } else {
      dispatch(push(PatientRoutePrefix + PatientRoutes.PatientSignIn));
    }
  };
