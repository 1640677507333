import { Flex, Form, Modal } from 'antd';
import { DarkButton } from 'components/dark-button';
import { Text } from 'components/mvp-typography';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import { ConcussionRXLogo } from 'features/sign-in/v2/concussionRx-logo';
import { styles } from 'features/sign-in/v2/styles';
import { CSSProperties, ReactNode, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { resolveError } from 'utils/formatters/error/resolve-error';
import { FormQuestion } from '../types/form-questions';
import { FormArgs, FormItem, RenderQuestion } from './user-add-question';
import { useForm } from 'antd/lib/form/Form';

export interface UserAddModalWrapperProps {
  onAddUser: (data: FormArgs) => Promise<void | any> | void | any;
  /**
   * The type of user to create. Must be in the singular form
   */
  type: string;
  containerStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  questions: FormQuestion[];
}

export default function UserAddModalWrapper(props: UserAddModalWrapperProps) {
  const typeToCreate = props.type.toLowerCase();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={props.containerStyle}>
      <DarkButton
        style={props.buttonStyle}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Add {typeToCreate}
      </DarkButton>
      <UserEditableDetailsModal
        {...props}
        onSubmit={props.onAddUser}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        submitText={`Create ${typeToCreate}`}
        actionProcessingDescription={`Sending invitation. Please do not close or reload this window or tab.`}
        questions={props.questions}
      />
    </div>
  );
}

export interface UserEditableDetailsModalProps {
  onSubmit: (data: FormArgs) => Promise<void | any> | void | any;
  isOpen: boolean;
  setIsOpen: (s: boolean) => void;
  submitText: ReactNode;
  actionProcessingDescription: ReactNode;
  initialValues?: Record<string, string | undefined | null>;
  questions: FormQuestion[];
}

export function UserEditableDetailsModal(props: UserEditableDetailsModalProps) {
  const [error, setError] = useState<Error | null>(null);
  const [isActionProcessing, setIsActionProcessing] = useState(false);
  const [form] = useForm();
  async function onAddUserSubmitEvent(event: FormArgs) {
    setIsActionProcessing(true);
    try {
      await props.onSubmit(event);
      setError(null);
    } catch (e) {
      setError(resolveError(e));
    } finally {
      setIsActionProcessing(false);
      props.setIsOpen(false);
    }
  }

  return (
    <Modal
      open={props.isOpen}
      onCancel={() => props.setIsOpen(false)}
      closable={!isActionProcessing}
      footer={() => <></>}
    >
      <Flex
        style={{
          ...styles.titleContainer
        }}
        justify="center"
      >
        <div
          style={{
            maxWidth: 300
          }}
        >
          <ConcussionRXLogo />
        </div>
      </Flex>
      <Form
        form={form}
        onFinish={onAddUserSubmitEvent}
        disabled={isActionProcessing}
        layout="vertical"
        initialValues={props.initialValues}
      >
        {props.questions.map(question => (
          <ErrorBoundary
            key={question.name}
            fallbackRender={props => {
              const error = resolveError(props.error);
              return <Text>{error.message}</Text>;
            }}
          >
            <RenderQuestion question={question} />
          </ErrorBoundary>
        ))}

        <FormItem>
          <div>
            <DarkButton
              htmlType="submit"
              loading={isActionProcessing}
              style={{
                padding: '1rem 2rem',
                height: 'auto'
              }}
            >
              {props.submitText}
            </DarkButton>
            {isActionProcessing && (
              <Text>{props.actionProcessingDescription}</Text>
            )}
          </div>
        </FormItem>
      </Form>

      {error && (
        <ErrorDisplay
          title="Something went wrong"
          description=""
          error={error}
          style={{
            width: '100%'
          }}
        />
      )}
    </Modal>
  );
}
