import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ConfigProvider, Layout } from 'antd';

import { auth } from 'api/firebase';

import { RouteSwitch } from 'components';
import { restoreAuth } from 'features/auth/authSlice';
import { Navigation } from 'features/navigation';

import {
  omitSideMenuRoutes,
  omitSideMenuRoutesPatient
} from 'constants/filters';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from './rootReducer';
import { Role } from 'features/auth/types';

export default function App() {
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  const user = useAppSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(restoreAuth(auth));
  }, [dispatch]);

  /**
   * Navigation should be rendered when:
   * - The user is logged in
   * - The current route is not in the ommitted routes list
   * - **(2024-01-29) The user is not a patient. Patients will not see the side menu.
   */
  const shouldRenderNavigation =
    user &&
    user.role !== Role.Patient &&
    !omitSideMenuRoutes.has(location) &&
    !omitSideMenuRoutesPatient.find(match => location.includes(match));
  return (
    <ConfigProvider
      /**
       * global theme override css
       * https://ant.design/docs/react/customize-theme
       *
       * During testing the variables in src/styles/theme.less and src/styles/palette.scss don't
       * impact the styles AntD uses
       */
      theme={{
        token: {
          colorPrimary: '#230056',
          colorPrimaryHover: '#A366FC',
          fontFamily: 'Comfortaa',
          colorTextPlaceholder: '#767679'
        },
        components: {
          Card: {
            headerFontSize: `${26 / 16}rem` as any
          },
          Button: {
            defaultColor: 'black'
          },
          Switch: {
            handleShadow: '0'
          }
        },
        cssVar: true
      }}
    >
      <Layout>
        {shouldRenderNavigation && <Navigation />}
        <RouteSwitch />
      </Layout>
    </ConfigProvider>
  );
}
