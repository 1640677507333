import { PatientInfoState } from 'components/patient-info/patient-info-slice-types';
import lodash from 'lodash';
import moment from 'moment';

export const ASSESSMENT_RETAKE_TIME = 7;

/**
 * Check if a user is currently doing an assessment
 *
 * **NOTE FOR BACKWARDS COMPATIBILITY THIS FUNCTION CURRENTLY USES THE IMPACT ASSESSMENT DATA**
 *
 * @param patientInfo
 * @returns
 */
export function userNeedsAssessment(
  patientInfo: Pick<PatientInfoState, 'impact'>
) {
  // Sort the data in descending order by date
  const sortedData = lodash
    .cloneDeep(patientInfo.impact)
    .sort((a, b) => moment(b.date).diff(moment(a.date)));

  /**
   * Return true if the user has no assessments, or if the last assessment was more than 7 days ago
   */
  return (
    sortedData.length === 0 ||
    moment(sortedData[0].date).isBefore(
      moment().subtract(ASSESSMENT_RETAKE_TIME, 'days')
    )
  );
}
