import React from 'react';

import { Button, Flex, Form, Input, Typography } from 'antd';

import { Role } from 'features/auth/types';
import { SignInProps } from '../SignIn';

import { auth } from 'api/firebase';
import firebase from 'firebase/compat/app';

import { Link } from 'react-router-dom';
import { Routes } from 'routes';

import { signInProcess } from 'features/auth/auth-action-sign-in-out';
import authSlice, { initialState } from 'features/auth/authSlice';
import { useReducer } from 'hooks/useReducer';

import { ConcussionRXLogo } from './concussionRx-logo';
import './Signin.scss';
import { styles } from './styles';

// { error && <span style={{color: 'red'}}>{error}</span> }
export const ErrorMessage = (props: { errorMessage: string }) => {
  return <span style={{ color: 'red' }}>{props.errorMessage}</span>;
};

export interface LoginProps {
  signInRole: Role;
  auth: firebase.auth.Auth;
}

/**
 * AntD link text component that works with react-router-dom
 * @param props
 * @returns
 */
export const LinkTextComponent: React.ComponentType<{
  navigate: () => void;
  href: string;
}> = props => (
  <Typography.Link
    {...props}
    onClick={event => {
      // Prevent the page from reloading
      event.preventDefault();
      props.navigate();
    }}
  />
);

function LoginForm(props: LoginProps) {
  const { auth, signInRole } = props;
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");

  const [{ error }, dispatch] = useReducer(authSlice, initialState); // isLoading, user,

  /**
   * WARN: `event` is cast to any and expects {email: string, password: string}
   * The login button onClick handler passes a React.FormEventHandler<HTMLElement>
   */
  const onSubmit = function (event: any) {
    console.log('loginForm: ', auth);
    const { email, password } = event;
    dispatch(signInProcess(email, password));
  };

  // const emailChange = function(event:React.ChangeEvent<HTMLInputElement>|undefined) {
  //   if (event) {
  //     setEmail(event.target.value);
  //     // console.log(email);
  //   }
  // }
  // const passwordChange = function(event:React.ChangeEvent<HTMLInputElement>|undefined) {
  //   if (event) {
  //     // console.log(event.target.value);
  //     setPassword(event.target.value);
  //   }
  // }

  return (
    // TODO: The parent needs to use flex so that this is positioned in the vertical center
    <Flex justify={'center'} align={'center'} flex={1}>
      <div style={styles.container}>
        <Form
          onFinish={onSubmit}
          name="login"
          initialValues={{
            remember: true
          }}
          className="login-form"
          style={styles.form}
          labelWrap={true}
          autoComplete="true"
        >
          <Flex style={styles.titleContainer} justify="center">
            <ConcussionRXLogo />
          </Flex>

          {/* Email input */}
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your email'
              },
              {
                type: 'email',
                message: 'Please enter a valid email'
              }
            ]}
          >
            <Input placeholder="Email" className="account_form_item" />
          </Form.Item>

          {/* Password input */}
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!'
              }
            ]}
          >
            <Input.Password
              type="password"
              placeholder="Password"
              className="account_form_item"
              visibilityToggle
            />
          </Form.Item>

          {error && <ErrorMessage errorMessage={error} />}

          {/* Forgot password */}
          <Flex>
            <Link
              to={{
                pathname: Routes.ForgotPassword,
                state: { signInRole: signInRole }
              }}
              className="login-form-forgot"
              style={{
                float: 'left',
                width: '100%',
                color: 'black'
              }}
              component={LinkTextComponent}
            >
              Forgot password?
            </Link>
          </Flex>

          {/* Login button */}
          <Flex justify={'center'} style={{ marginTop: '3rem' }}>
            <Button
              onSubmit={onSubmit}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={styles.button}
            >
              Login
            </Button>
          </Flex>
        </Form>
      </div>
    </Flex>
  );
}

export const SignInV2: React.FC<SignInProps> = (props: SignInProps) => (
  <LoginForm auth={auth} signInRole={props.signInRole} />
);
