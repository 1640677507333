import React from 'react';
import { grey } from 'styles/colors';

interface ChatMessageProps {
  content: string;
  icon?: React.ReactNode;
  noBackground?: boolean;
  color?: string;
}

export function ChatMessage(props: ChatMessageProps) {
  return (
    <div
      style={{
        background: props.noBackground ? 'none' : grey['light'],
        color: props.color ?? grey['medium'],
        borderRadius: 10,
        padding: '0.6rem 0.9rem',
        whiteSpace: 'preserve'
      }}
    >
      {props.content}
    </div>
  );
}
