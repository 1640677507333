import { Flex, Modal, Table, TableColumnsType, Tag, Tooltip } from 'antd';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import UserAddModal from '../user-add-modal';

import { Title } from 'components/mvp-typography';
import { resolveError } from 'utils/formatters/error/resolve-error';
import { CrxSearch } from '../../../../../components/search-component/search-component';
import {
  ActionTag,
  ActionTagErrorContextProvider,
  ActionTagErrorDisplay
} from '../action-tag/action-tag-component';
import { loadUserTableData } from './user-table-services';
import {
  BaseTableUserAPIResponse,
  TableRow,
  UserListProps,
  baseTableUserRecordSchema
} from './user-table-types';

/**
 * A table to list and modify users.
 * Design expectations - users are pending or confirmed, users are added with invites.
 *
 * @param props
 * @returns
 */
export default function UserTable<T extends BaseTableUserAPIResponse>(
  props: UserListProps<T>
) {
  const [data, setData] = useState<{
    data: TableRow<T>[];
    isLoading: boolean;
  }>({
    data: [],
    isLoading: true
  });
  const [error, setError] = useState<Error | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const { clinicId } = props;

  const tableApi = useCallback(
    (args: Omit<UserListProps<T>['api'], 'clinicId'>) =>
      props.api({ clinicId, ...args }),
    // eslint-disable-next-line
    [clinicId]
  );

  const loadData = useCallback(
    async (refreshData = false) => {
      try {
        await loadUserTableData({
          api: args => tableApi({ ...args }),
          onError: error => setError(error),
          onNewData: changeDataFn => setData(oldData => changeDataFn(oldData)),
          refreshData: refreshData,
          actionCallback: state => {
            if (state === 'loading-end')
              setData(_ => ({ ..._, isLoading: false }));
            else if (state === 'loading-start')
              setData(_ => ({ ..._, isLoading: true }));
          }
        });
      } catch (e) {
        const error = resolveError(e);
        setError(error);

        // Stop loading when there is an error
        setData(_ => ({ ..._, isLoading: false }));
      }
    },
    [tableApi]
  );

  /**
   * Load data whenever the API or the clinicID changes
   */
  useEffect(
    () => {
      loadData();
    },
    // eslint-disable-next-line
    [props.api, props.clinicId]
  );

  const filteredData = useMemo(() => {
    if (searchQuery === null) return data.data;
    return data.data.filter(record =>
      JSON.stringify({
        name: [record.firstName, record.lastName].join(' '),
        firstName: record.firstName,
        lastName: record.lastName,
        email: record.email
      })
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  }, [data.data, searchQuery]);

  /**
   * The columns will only change when extraColumns changes
   */
  const columns: TableColumnsType<TableRow<T>> = useMemo(
    () => [
      {
        title: 'Full Name',
        dataIndex: 'firstName',
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        render: (_, r) => [r.firstName, r.lastName].join(' ')
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email)
      },
      ...(props.extraColumns ?? []),
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (_value, record) => {
          const actions = props.actions ?? [];

          const tags = actions.map((action, index) => {
            if (
              action.shouldShow === false ||
              (typeof action.shouldShow === 'function' &&
                !action.shouldShow(record))
            ) {
              return (
                <Fragment key={`action-${index}-${action.name}`}></Fragment>
              );
            }

            return (
              <ActionTag
                key={`action-${index}-${action.name}`}
                action={{
                  ...action,
                  onClick: async (...data) => {
                    await action.onClick(...data);
                    await loadData(true);
                  }
                }}
                record={record}
              />
            );
          });

          return tags;
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: (a, b) => +a.invitePending - +b.invitePending,
        render(_value, record) {
          return (
            <Tooltip
              title={
                record.invitePending
                  ? 'The user has been invited to the clinic but has not accepted their invitation'
                  : 'The user has accepted their invitation'
              }
            >
              <Tag
                color={record.invitePending ? 'geekblue' : 'green'}
                style={{ padding: '0.1rem' }}
              >
                {record.invitePending ? 'Pending' : 'Accepted'}
              </Tag>
            </Tooltip>
          );
        }
      }
    ],
    [props.extraColumns, props.actions, loadData]
  );
  return (
    <ActionTagErrorContextProvider>
      <Flex justify={'space-between'}>
        <Title>{props.typeUnit}s</Title>
        <div>
          <CrxSearch
            onSearch={val => {
              if (val === '') {
                setSearchQuery(null);
                return;
              }
              setSearchQuery(val);
            }}
            placeholder={[
              'Search',
              props.typeUnit ? props.typeUnit + 's' : null
            ]
              .filter(_ => !!_)
              .join(' ')}
          />
        </div>
      </Flex>

      <Table
        loading={data.isLoading}
        dataSource={filteredData}
        columns={columns}
      />

      <UserAddModal
        containerStyle={{
          /**
           * Align the button with the table pagination component.
           * The pagination component is nested in the AntD table component and is not accessible
           */
          marginTop: '-3.2rem',
          /**
           * Increase the z-index to allow the button to be clicked if the table renders something above it
           */
          zIndex: 10
        }}
        onAddUser={async userData => {
          baseTableUserRecordSchema.parse(userData);
          await props.apiAddUser(userData, props.clinicId);

          await loadData(true);
        }}
        type={props.typeUnit.toLowerCase()}
        questions={props.userAddQuestions}
      />

      <Modal title="Test"></Modal>
      <ActionTagErrorDisplay />
      {error && (
        <ErrorDisplay
          title={`Something went wrong when loading data`}
          description={
            'If you think this is an error please send an error report to contact@concussionrx.com'
          }
          error={error}
        />
      )}
    </ActionTagErrorContextProvider>
  );
}
