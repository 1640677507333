import React from 'react';

export function Gradient(
  props: { to: string; from: string } & Pick<
    React.HTMLAttributes<HTMLDivElement>,
    'style'
  >
) {
  return (
    <div
      style={{
        ...props.style,
        backgroundImage: `linear-gradient(${props.from}, ${props.to})`
      }}
    />
  );
}
