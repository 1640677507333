import { uniqBy } from 'lodash';
import { resolveError } from 'utils/formatters/error/resolve-error';
import { getFirstName, getLastName } from 'utils/string/normalise';
import {
  BaseTableUserAPIResponse,
  ListUserReturnResult,
  TableRow
} from './user-table-types';

/**
 * Process the results from the API to a TableRow
 * @param data
 * @returns
 */
export function processAPIResult<T extends BaseTableUserAPIResponse>(
  data: ListUserReturnResult<T>
): TableRow<T>[] {
  return [
    ...data.inClinic.map(_ => ({ ..._, invitePending: false })),
    ...data.pendingInClinic.map(_ => ({
      ..._,
      invitePending: true
    }))
  ].map(_ => ({
    id: _.id as string,
    key: _.id as string,
    firstName: getFirstName(_.displayName),
    lastName: getLastName(_.displayName),
    email: _.email,
    invitePending: _.invitePending,
    raw: _
  }));
}

/**
 * Method to load paginated data from an API
 */
export async function loadUserTableData<
  T extends BaseTableUserAPIResponse
>(options: {
  api: (a?: any) => Promise<void | any>;
  actionCallback: (state: 'loading-start' | 'loading-end') => void | any;
  onNewData: (
    changeData: (oldData: { data: TableRow<T>[] }) => any
  ) => void | any;
  onError: (error: Error) => void | any;
  refreshData?: boolean;
}) {
  if (options.api.name == undefined) {
    console.warn(
      `The provided API does not have a name set. This will make error logs ambiguous`
    );
  }

  const refreshData = options.refreshData ?? false;

  try {
    // Fetch from the API
    let apiResult = await options.api();

    // Stop loading
    options.actionCallback('loading-end');

    // While there are users to list
    while (
      apiResult.data.inClinic.length !== 0 ||
      apiResult.data.pendingInClinic.length !== 0
    ) {
      // Process the API result to TableRow's
      const processedData = processAPIResult(apiResult.data);

      // Queue state updating
      options.onNewData(oldState => {
        let tableData = oldState.data;

        /**
         * Refresh data by replacing old records with new records
         */
        if (refreshData) {
          const resultMap: Record<string, TableRow<T>> = processedData.reduce(
            (acc, cur) => ({ ...acc, [cur.key]: cur }),
            {}
          );
          tableData = oldState.data.map(_ => {
            const newRecord = resultMap[_.key];

            /**
             * Replace the record if the record changed
             */
            if (
              // The record exists
              newRecord &&
              // The old record doesn't match the new record
              JSON.stringify(_) !== JSON.stringify(newRecord)
            ) {
              return newRecord;
            }

            return _;
          });
        }

        return {
          // Stop loading
          isLoading: false,
          // Dedupe the data by the `key`
          data: uniqBy([...tableData, ...processedData], _ => _.key)
        };
      });

      // TODO: Reduce re-renders when one of the arrays goes to 0 before the other one
      apiResult = await options.api({
        // Set the lastUserId to the last element of the array
        lastUserId:
          apiResult.data.inClinic[apiResult.data.inClinic.length - 1]?.id,
        lastPendingUserId:
          apiResult.data.pendingInClinic[
            apiResult.data.pendingInClinic.length - 1
          ]?.id
      });
    }
  } catch (e) {
    console.error(`Failed to fetch from the '${options.api.name}' API`);

    const error = resolveError(e);
    console.log(error);

    // Stop loading when there is an error
    options.actionCallback('loading-end');
    options.onError(error);
  }
}
