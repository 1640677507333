import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createRepo, impactPassportIdExists } from 'api/firebase';
import { TestRecord } from 'api/impact/types';
import {
  Collection,
  Inventory,
  LastTestCompleted,
  Patient,
  PatientImpactProfile,
  PatientProfile,
  // ApiSubCollection,
  PatientSubcollection,
  TestType
} from 'documents';
import { getName } from './get-name';
import { z } from 'zod';

export interface ImPACTSlice {
  verified: boolean;
  passportId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  birthdate?: string | null;
  email?: string | null;
  userLoaded?: boolean;
  nextTestToComplete?: TestType;
  organizationID?: number | null;
  isLoading: boolean;
}

export const initialState: ImPACTSlice = {
  verified: false,
  passportId: null,
  firstName: null,
  lastName: null,
  birthdate: null,
  email: null,
  userLoaded: false,
  nextTestToComplete: TestType.POST_INJURY_ONE,
  organizationID: null,
  isLoading: false
};

export const initialImpactSliceLoad = createAsyncThunk(
  'impactAPI/initialLoadThunk',
  async (patientId: string) => {
    /** Prepare data repos **/
    const patientRepo = createRepo<Patient>(Collection.Patients);
    const patientProfileRepo = createRepo<PatientProfile>(
      Collection.PatientProfiles
    );

    /** Fetch user profile **/
    const patient = await patientRepo.find(patientId);
    const patientProfile = await patientProfileRepo.find(patientId);
    const patientImpactProfile = await getPatientImpactProfile(patientId);
    const { verified, passportId, lastTestCompleted } = patientImpactProfile;

    // const PatientImpactApi = createRepo<PatientImpactApi>()
    // console.log("impactAPISlice: ",patientId, patient, patientProfile);
    // fetching the information that's saved for impact
    // `${Collections.Patients}/${patientID}/${PatientSubCollection.inventoriesImpact}`
    /*
      should contain:
      test information from redirect(?)
      can have a trigger function to send to analyze function and write results into:
      ${Collections.Patients}/${patientID}/${PatientSubCollection.analyzeResults};
    */

    // fetching information that's related for configurations and settings for impact(?)
    // ${Collections.Patients}/${patientID}/${PatientSubCollection.Apis/${ApiSubCollection.Impact}}

    /*
      should contain:
      - verified boolean
      - lastCompletedImpact: 0,1,2,3,4
      because apparently we should be doing post-injury-one, two, three, four

    */

    // const symptomRepo = createRepo<PatientSymptom>(
    //   `${Collection.Patients}/${patientId}/${PatientSubcollection.Symptoms}`
    // );
    // const qolRepo = createRepo<QualityOfLife>(
    //   `${Collection.Patients}/${patientId}/${PatientSubcollection.QualityOfLife}`
    // );
    // const patientRepo = createRepo<Patient>(Collection.Patients);
    // const symptomCalcInfo = createRepo<SymptomCalcInfo>(
    //   `${Collection.Patients}/${patientId}/${PatientSubcollection.SymptomCalcInfo}`
    // );

    // const [symptoms, qols, patient, symptomCalcInfoArr] = await Promise.all([
    //   symptomRepo.getList({ orderBy: ['date', 'desc'] }),
    //   qolRepo.getList({ orderBy: ['date', 'desc'] }),
    //   patientRepo.find(patientId),
    //   symptomCalcInfo.getList()
    // ]);

    // if (!patient) throw new Error(`Patient (id=${patientId}) not found`);

    // return { symptoms, qols, patient, symptomCalcInfoArr };
    return { verified, passportId, patient, patientProfile, lastTestCompleted };
  }
);

const impactSlice = createSlice({
  name: 'impactAPI',
  initialState,
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    stopLoading: state => {
      state.isLoading = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(initialImpactSliceLoad.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(initialImpactSliceLoad.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(initialImpactSliceLoad.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const {
        verified,
        passportId,
        patient,
        patientProfile,
        lastTestCompleted
      } = payload;
      // Find the patients first and last name from the display name
      const name = getName(patient?.displayName);
      const email = patient?.email;

      //  we need to extract birthday from patientProfile and maybe language(?) if needed
      const birthdate = patientProfile?.birthDate?.format('MM/DD/YYYY');

      // this prepopulates the impactAPI with data so the user doesn't have to submit things that shouldn't change;
      state.firstName = name?.firstName;
      state.lastName = name?.lastName;
      state.email = email;
      state.birthdate = birthdate;

      state.verified = verified;
      state.passportId = passportId;
      // state.patient = patient;
      state.userLoaded = true;

      // get next testtype to complete for impactAPI
      if (lastTestCompleted == LastTestCompleted.NONE) {
        state.nextTestToComplete = TestType.POST_INJURY_ONE;
      } else if (lastTestCompleted == LastTestCompleted.POST_INJURY_ONE) {
        state.nextTestToComplete = TestType.POST_INJURY_TWO;
      } else if (lastTestCompleted == LastTestCompleted.POST_INJURY_TWO) {
        state.nextTestToComplete = TestType.POST_INJURY_THREE;
      } else if (lastTestCompleted == LastTestCompleted.POST_INJURY_THREE) {
        state.nextTestToComplete = TestType.POST_INJURY_FOUR;
      } else if (lastTestCompleted == LastTestCompleted.POST_INJURY_FOUR) {
        //  we stay on post injury four test here(?) or do we wrap around
        state.nextTestToComplete = TestType.POST_INJURY_FOUR;
      }
    });
  }
});

export const ImpactSliceReducer = impactSlice.reducer;
export default impactSlice.reducer;

/**
 * Retrieve the ImPACT profile of a patient.
 * Creates an ImPACT profile if none exists
 *
 * This method should be used only when `PatientSubcollection.ImpactApi`
 * is not used anywhere else in the context
 * @param patientId The Firestore ID of a patient
 * @returns A valid patient impact profile if it exists.
 * Otherwise a profile with invalid data is returned
 */
export async function getPatientImpactProfile(patientId: string) {
  const patientImpactRepo = createRepo<PatientImpactProfile>(
    `${Collection.Patients}/${patientId}/${PatientSubcollection.ImpactApi}`
  );

  let patientImpactProfile = await patientImpactRepo.find(patientId);

  // If there is no patient impact profile
  if (patientImpactProfile === undefined) {
    // Then initialise a default value
    // TODO: This should happen on the backend when a user is created
    patientImpactProfile = {
      id: patientId,
      organizationId: -1,
      verified: false,
      passportId: null as any,
      lastTestCompleted: LastTestCompleted.NONE,
      testTakerId: ''
    };

    await patientImpactRepo.set(patientImpactProfile);
  }
  return patientImpactProfile;
}

export const createImpactInventoryRepo = (patientId: string) =>
  createRepo<Inventory & TestRecord>(
    [
      // WARN: USING LEGACY PATIENTS COLLECTION FOR ASSESSMENT DATA
      Collection.Patients,
      patientId,
      PatientSubcollection.InventoriesImpact
    ].join('/')
  );

/**
 * Check if an ImPACT PassportID is valid
 *
 * TODO: This check should be done on the backend
 * @param passportId
 * @returns
 */
export async function passportIdExists(passportId: string): Promise<boolean> {
  return z
    .boolean()
    .parse(await (await impactPassportIdExists(passportId)).data);
}
