import { FormItemProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';

export type BaseFormQuestionProps = {
  label: string;
  name: string;
  disabled?: boolean;
} & Pick<FormItemProps, 'rules'>;
export type FormQuestion = (
  | {
      type: 'string';
    }
  | {
      type: 'select';
      loading?: boolean;
      options: DefaultOptionType[];
    }
  | {
      type: 'custom';
      component: (props: BaseFormQuestionProps) => JSX.Element;
    }
) &
  BaseFormQuestionProps;

export const BASE_USER_ADD_QUESTIONS: FormQuestion[] = [
  {
    type: 'string',
    name: 'firstName',
    label: 'First name',
    rules: [{ required: true }]
  },
  {
    type: 'string',
    name: 'lastName',
    label: 'Last name',
    rules: [{ required: true }]
  },
  {
    type: 'string',
    name: 'email',
    label: 'Email',
    rules: [{ required: true }, { type: 'email' }]
  }
];
