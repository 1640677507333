import { Redirect, Route, Switch } from 'react-router-dom';

import { ClinicianRoutePrefix, ClinicRoutes, Routes } from 'routes';

import {
  PrivateRoute,
  PrivateRouteProps
} from 'components/routes/private-route';

import { Role } from 'features/auth/types';

import ClinicUserManagement from 'features/clinician/clinic-user-management';
import ClinicianHomeV2 from 'features/clinician/clinician-home-v2/clinician-home-v2';
import ClinicInfoEdit from 'features/clinician/clinic-info-edit/clinic-info-edit';
import { ClinicianQueryPage } from 'features/clinician/clinician-query';

export const ClinicianRoutesMap: PrivateRouteProps[] = [
  {
    path: ClinicRoutes.ClinicUserManagement,
    component: ClinicUserManagement,
    roles: [Role.ClinicAdmin]
  },
  {
    path: ClinicRoutes.ClinicInfoEdit,
    component: ClinicInfoEdit,
    roles: [Role.ClinicAdmin]
  },
  {
    path: ClinicRoutes.CrxCompanion,
    component: ClinicianQueryPage,
    roles: [Role.ClinicAdmin, Role.Clinician]
  }
];

export function ClinicianRouteSwitch() {
  return (
    <Switch>
      <Route exact path={ClinicianRoutePrefix}>
        <Redirect to={Routes.ClinicianHome} />
      </Route>

      <PrivateRoute
        roles={[Role.Clinician, Role.ClinicAdmin]}
        path={Routes.ClinicianHome}
        component={ClinicianHomeV2}
        redirectPath={Routes.ClinicianSignIn}
      />

      {ClinicianRoutesMap.map(config => (
        <PrivateRoute
          key={`clinicianroutesmap-${config.path}`}
          roles={config.roles ?? [Role.Clinician, Role.ClinicAdmin]}
          path={config.path}
          component={config.component}
          redirectPath={Routes.ClinicianSignIn}
        />
      ))}

      <PrivateRoute
        roles={[Role.Clinician, Role.ClinicAdmin]}
        path={ClinicianRoutePrefix}
        component={() => <Redirect to={ClinicianRoutePrefix} />}
        redirectPath={'/'}
      />
    </Switch>
  );
}
