import { listClinicians } from 'api/firebase';
import { uniqBy } from 'lodash';
import { useState, useEffect } from 'react';
import {
  FormQuestion,
  BASE_USER_ADD_QUESTIONS
} from '../../../types/form-questions';
import { Input } from 'antd';

export const EXTRA_PATIENT_QUESTIONS: FormQuestion[] = [
  {
    type: 'custom',
    name: 'phoneNumber',
    label: 'Phone Number',
    rules: [
      { required: false },
      {
        pattern: /^\+[1-9]\d{1,14}$/,
        message: `The phone number should be in E.164 format. The number should start with '+' and have no dashes. e.g: '+11234567890'`
      }
    ],
    component: props => <Input {...props} disabled={props.disabled} />
  }
];

/**
 * Hook to generate the questions for patient add and edit actions
 * @param clinicId
 * @returns
 */
export function useResolvePatientUserAddQuestions(clinicId: string | null) {
  const [patientUserAddQuestions, setPatientUserAddQuestions] = useState<
    FormQuestion[]
  >([
    ...BASE_USER_ADD_QUESTIONS,
    ...EXTRA_PATIENT_QUESTIONS,
    {
      type: 'select',
      name: 'clinicianId',
      label: 'Clinician',
      rules: [{ required: true }, { type: 'enum', enum: [] }],
      options: [],

      // Disable the select until clinician data is ready to list
      loading: true,
      disabled: true
    }
  ]);

  useEffect(() => {
    async function getClinicians(clinicId: string) {
      const result = await listClinicians({
        clinicId
      });

      const clinicians = [
        ...result.data.inClinic,
        ...result.data.pendingInClinic
      ];
      const options = uniqBy(
        clinicians.map(_ => ({
          label: `${_.displayName} - ${_.id}`,
          value: _.id
        })),
        _ => _.value
      );

      // Set the clinicians list to be available in the patients add-user options
      setPatientUserAddQuestions([
        ...BASE_USER_ADD_QUESTIONS,
        ...EXTRA_PATIENT_QUESTIONS,
        {
          type: 'select',
          name: 'clinicianId',
          label: 'Clinician',
          rules: [
            { required: true },
            { type: 'enum', enum: options.map(_ => _.value) }
          ],
          options
        }
      ]);
    }

    if (!clinicId) return;
    getClinicians(clinicId);
  }, [clinicId]);
  return patientUserAddQuestions;
}
