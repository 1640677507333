import React, { useState } from 'react';

import { LeftOutlined } from '@ant-design/icons';
import { Layout, Row } from 'antd';

import { SignOutButton } from 'components';
import { goBack, replace } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { ModalExitOnConfirmation } from 'components';

import { GenericButton } from 'components/generic-button';
import './Header.scss';

const { Header } = Layout;

interface BaseHeaderProps {
  marginBottom?: number;
  headerStyle?: React.CSSProperties;
}

export const BaseHeader: React.FC<BaseHeaderProps> = props => {
  // const user = useSelector((state: RootState) => state.auth.user);
  // const [showModal, toggleModal] = useState(false);

  return (
    <Header
      className={'crx-header'}
      style={{
        height: '60px',
        backgroundColor: 'white',
        ...props.headerStyle,
        marginBottom: props.marginBottom
      }}
    >
      <Row justify="space-between" align="middle">
        <SignOutButton label="Log out" rounded={true} />
      </Row>
    </Header>
  );
};

export interface QuestionnaireHeaderProps {
  /**
   * The title of the header
   */
  title: string;
  /**
   * The label of the back button
   */
  headerButtonLabel?: string;
  /**
   * Set this to `true` if the header button should be hidden.
   * This is `false` by default.
   */
  hideHeaderButton?: boolean;
  headerButtonOverride?: () => JSX.Element;
  // onBack: () => void;
  // on button click on back(?)
  // label for button?
}

export function QuestionnaireHeader(props: QuestionnaireHeaderProps) {
  const dispatch = useDispatch();

  const back = () => dispatch(goBack());
  const ButtonRender = props.headerButtonOverride ?? GenericButton;
  return (
    <Header
      className={'crx-header'}
      style={{ height: '40px', backgroundColor: 'white' }}
    >
      <Row align="middle">
        {!props.hideHeaderButton && (
          <ButtonRender
            label={props.headerButtonLabel ?? 'Back'}
            rounded={true}
            icon={<LeftOutlined />}
            onClick={back}
          />
        )}
        <span className="questionnaire-header__title">
          {props.title ?? 'Questionnaire'}
        </span>
      </Row>
    </Header>
  );
}

// export interface FaqProps {
//   min: number;
//   max: number;
//   instructions?: string;
//   description?: string;
// }

interface OutcomeMeasuresHeaderProps {
  title: string;
  label: string;
  onExit?: () => void;
  // onNext: () => void;
  backgroundColor?: string;
  // faq?: FaqProps;
}
export function OutcomeMeasuresHeader({
  backgroundColor = 'white',
  ...props
}: OutcomeMeasuresHeaderProps) {
  // const [showModal, toggleModal] = useState(false);
  const [showExitModal, toggleExitModal] = useState(false);
  const dispatch = useDispatch();
  function handleExitOnClose() {
    props.onExit?.();
    dispatch(replace('/'));
  }
  function handleExitOnCancel() {
    toggleExitModal(false);
  }

  return (
    <>
      <Header
        className="crx-header"
        style={{ height: '84px', backgroundColor: backgroundColor }}
      >
        <Row align="middle" justify="space-between">
          <Row align="middle">
            <GenericButton
              label={props.label}
              rounded={true}
              icon={<LeftOutlined />}
              onClick={() => {
                toggleExitModal(!showExitModal);
              }}
            />
            <span className="questionnaire-header__title">{props.title}</span>
          </Row>

          {/* <QuestionCircleFilled
            onClick={() => {
              toggleModal(!showModal);
            }}
            style={{ fontSize: '25px' }}
          />
          {/*<FaqModal showModal={showModal} onClose={() => {toggleModal(false)}}/>*/}
          {
            /* <ModalRatingInstructionAndFaqs
            showModal={showModal}
            onClose={() => toggleModal(false)}
          /> */
            <ModalExitOnConfirmation
              showModal={showExitModal}
              onClose={handleExitOnClose}
              onCancel={handleExitOnCancel}
            />
          }
        </Row>
      </Header>
    </>
  );
}

interface SaveAndExitHeaderProps {
  title: string;
  label: string;
  onExit: () => void;
  backgroundColor?: string;
}
export const SaveAndExitHeader = ({
  title,
  label = 'Save & exit',
  onExit,
  backgroundColor = 'white'
}: SaveAndExitHeaderProps) => {
  return (
    <Header
      className="crx-header"
      style={{ height: '84px', backgroundColor: backgroundColor }}
    >
      <Row align="middle">
        <GenericButton
          minWidth={125}
          label={label}
          rounded={true}
          icon={<LeftOutlined />}
          onClick={onExit}
          baseFont={true}
        />
        <span className="questionnaire-header__title">{title}</span>
      </Row>
    </Header>
  );
};
